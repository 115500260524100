import React, { useState } from 'react';
import { getLabel } from '../common/label/Label.library';
import { Formik, FormikTouched } from 'formik';
import { Button, CardContent, Card } from '@mui/material';
import { makeJSONPostRequest, makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { TextInput } from '../common/text.input/TextInput';
import { CheckboxInput } from '../common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import './ProgramAttributes.css';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { getClientId } from '../../services/client/client'
import { Program } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { DialogModal } from '../common/dialog.modal/DialogModal';
import { SetSuccessMessageAction } from '../../actions/successMessageAction';

interface Props {
    program: Program
}

export const ProgramInfo: React.FC<Props> = ({ program }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [submitOpenJoin, setSubmitOpenJoin] = useState(false);
    const [submitOpenLeave, setSubmitOpenLeave] = useState(false);
    const [newId, setNewId] = useState(-1);
    const [updatedProgram, setUpdatedProgram] = useState<Program>();

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};

        if (!values.Name.trim()) {
            errors.Name = getLabel('validation_message_required');
        } else if (/[,'\"|\t]/.test(values.Name)) {
            errors.Name = getLabel('validation_message_special_characters');
        }
        return errors;
    }

    const formClean = (touched: FormikTouched<{ Name: any; Description: any; Active: any; ExternalId: any; }>) => {
        return (touched.Name === undefined &&
            touched.Description === undefined &&
            touched.Active === undefined &&
            touched.ExternalId === undefined);
    }

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.PROGRAMS));
    }

    const saveProgram = async (values: any, actions: any) => {
        try {
            setUpdatedProgram({ id: program.id, name: values.Name, description: values.Description, externalId: values.ExternalId, active: values.Active, products: program.products });
            if (values.Active != program.active || program.id === -1) {
                values.Active ? await submitProgram(values) : setSubmitOpenLeave(true);
            }
            else {
                await submitProgram(values);
            }
        } finally {
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    const submitProgram = async (values: any = null) => {
        const data = {
            Id: program.id,
            Name: values ? values.Name : updatedProgram?.name,
            Description: values ? values.Description : updatedProgram?.description,
            Active: values ? values.Active : updatedProgram?.active,
            ExternalId: values ? values.ExternalId : updatedProgram?.externalId,
            ClientId: getClientId()
        };
        if (!data.Description) delete data.Description;
        if (!data.ExternalId) delete data.ExternalId;

        const response = (program.id === -1) ?
            await makeJSONPostRequest(ApiUrls.CREATE_PROGRAM, dispatch, data) :
            await makeJSONPostRequest(createUrl(ApiUrls.EDIT_PROGRAM, { programId: program.id }), dispatch, data);
        if (data.Active) {
            var excluded = await makeJSONGetRequest(createUrl(ApiUrls.HAS_NOT_INCLUDED_CYCLES, { programId: program.id }), dispatch, null, false, false);
            if (excluded.body) {
                setNewId(response.body.programId);
                setSubmitOpenJoin(true);
            }
            else {
                dispatch(SetSuccessMessageAction((program.id === -1) ? 'program_success_add_text' : 'program_success_edit_text'));
                navigate(createRoute(ApplicationRoutes.PROGRAMS));
            }
        }
        else {
            dispatch(SetSuccessMessageAction((program.id === -1) ? 'program_success_add_text' : 'program_success_edit_text'));
            navigate(createRoute(ApplicationRoutes.PROGRAMS));
        }
    }

    const approveSubmitCascade = (async () => {
        setSubmitOpenJoin(false);
        await makeJSONPostRequest(createUrl(ApiUrls.JOIN_UPCOMING_CYCLES, { programId: program.id != -1 ? program.id : newId }), dispatch, null, false, false);
        dispatch(SetSuccessMessageAction((program.id === -1) ? 'program_success_add_text' : 'program_success_edit_text'));
        navigate(createRoute(ApplicationRoutes.PROGRAMS));
    })

    const declineSubmitCascade = (() => {
        setSubmitOpenJoin(false);
        dispatch(SetSuccessMessageAction((program.id === -1) ? 'program_success_add_text' : 'program_success_edit_text'));
        navigate(createRoute(ApplicationRoutes.PROGRAMS));
    })

    const completeSubmit = (async () => {
        setSubmitOpenLeave(false);
        await submitProgram();
    })

    const cancelSubmit = (() => {
        setSubmitOpenLeave(false);
    })

    return (
        <Card>
            <DialogModal id="CascadeJoin" open={submitOpenJoin} labelLeft={getLabel('program_cascade_dont_add_button')} labelRight={getLabel('program_cascade_add_button')} onClickLeft={declineSubmitCascade} onClickRight={approveSubmitCascade}
                title={getLabel('program_cascade_add_dialog_message')}></DialogModal>
            <DialogModal id="CascadeLeave" open={submitOpenLeave} labelLeft={getLabel('program_dont_remove_button')} labelRight={getLabel('program_remove_button')} onClickLeft={cancelSubmit} onClickRight={completeSubmit}
                title={getLabel('program_remove_dialog_message')}></DialogModal>
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        Name: program.name ?? '',
                        Description: program.description ?? '',
                        Active: program.active ?? true,
                        ExternalId: program.externalId ?? ''
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        saveProgram(values, actions);
                    }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className="program-grid">
                                <TextInput name="Name" label="program_attributes_name_label" fullwidth={false} />
                                <TextInput name="Description" label="program_attributes_description_label" fullwidth={false} multiline={true} maxLength={null} />
                                <TextInput name="ExternalId" label="program_attributes_external_system_id_label" fullwidth={false} />
                            </div>
                            <div className="flex">
                                <span className="flexIndentLeft"></span>
                                <CheckboxInput name="Active" label="program_attributes_active_label" />
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>{getLabel('program_attributes_cancel_button_label')}</Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={formClean(props.touched) || props.isSubmitting}>{getLabel('program_attributes_submit_button_label')}</Button>
                            </div>
                        </form>)}
                </Formik>
            </CardContent>
        </Card>
    )
}
