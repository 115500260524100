import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Checkbox, FormControlLabel, List, ListItem, Paper } from "@mui/material";
import { Provider, ProviderDesignation } from "../../../interfaces/ApiInterfaces";
import { useActingForId } from "../../../hooks/useActingFor";
import { makeJSONPostRequest } from "../../../services/ajax/ajax";
import { ApiUrls } from "../../../constants/ApiUrls";
import { SearchDefaults } from "../../../constants/SearchDefaults";
import { useDispatch, useSelector } from "react-redux";
import { ClearUserMessageAction, SetUserMessageSuccessAction } from "../../../actions/userMessageAction";
import { getLabel } from "../../common/label/Label.library";
import { TabValue } from "../../../constants/TabValue";
import { createRoute, ApplicationRoutes } from "../../../constants/ApplicationRoutes";
import { formatDate } from "../../../services/date/date";
import { PagingFooter } from "../../common/paging/PagingFooter";
import { useNavigate } from "react-router-dom";
import { hasPermissions } from "../../../services/auth/auth";
import { Permissions } from '../../../constants/Permissions';
import { successMessageValue } from "../../../reducers/rootReducer";
import { AppState } from "../../../store/configureStore";
import { ClearSuccessMessageAction } from "../../../actions/successMessageAction";
import './ProviderDesignations.css';

interface Props {
    provider: Provider;
}

export const ProviderDesignations: React.FC<Props> = ({ provider }) => {
    const acnActingForId = useActingForId();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [designations, setDesignations] = useState<ProviderDesignation[]>([]);
    const [totalResults, setTotalResults] = useState(-1);
    const [page, setPage] = useState(0);
    const successMessage = useSelector<AppState, string>(successMessageValue);
    const [includeInactive, setIncludeInactive] = useState(false);
    
    useEffect(() => {
        getDesignations(1, true);
    }, [provider.id, acnActingForId, includeInactive]);

    const getDesignations = async (newPage: number, showSucessMessage: boolean = true) => {
        const data = {
            ProviderId: provider.id,
            AccountableCareNetworkId: acnActingForId,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "StartDate",
            OrderByDir: "DESC",
            IncludeInactive: includeInactive
        };
        const response = await makeJSONPostRequest(ApiUrls.GET_DESIGNATIONS, dispatch, data, true, true);

        if (showSucessMessage) {
            if (!!successMessage) {
                dispatch(SetUserMessageSuccessAction(successMessage));
            }
            else if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('provider_designations_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('provider_designations_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('provider_designations_result_many', { totalCount: response.body.totalCount })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }
        
        dispatch(ClearSuccessMessageAction());
        setDesignations(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    }

    const createNewDesignation = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_DESIGNATION, { providerId: provider.id, id: -1, tab: TabValue.DESIGNATIONS_ATTRIBUTES }))
    }

    const selectDesignation = (designation: ProviderDesignation) => {
        navigate(createRoute(ApplicationRoutes.EDIT_DESIGNATION, { providerId: provider.id, id: designation.id, tab: TabValue.DESIGNATIONS_ATTRIBUTES }));
    }

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        await getDesignations(value, false);
    };

    return (
        <Card className="provider-designations">
            <CardContent>
                <div className="provider-designations-buttons">
                    <div>
                        {hasPermissions(Permissions.CAN_CREATE_OR_UPDATE_DESIGNATION) &&
                            <Button id="addButton" variant="contained" color="primary" onClick={createNewDesignation}>{getLabel('provider_designations_list_add')}</Button>}
                    </div>
                    <FormControlLabel
                        label={getLabel("provider_designation_filter_include_inactive")}
                        control={
                            <Checkbox
                                color="primary"
                                checked={includeInactive}
                                onChange={() => setIncludeInactive(!includeInactive)}
                            />
                        }
                    />
                </div>
                <List id="resultList">
                    {designations.map((designation) => <Paper key={designation.id}> <ListItem className="row" button onClick={() => selectDesignation(designation)}>
                        <div className="designation-row">
                            <div>
                                {designation.active && <div className="name">{designation.designationType.name}</div>}
                                {!designation.active && <div className="name inactive-result">{getLabel('inactive_name', { name: designation.designationType.name })}</div>}
                                <div className="designation-row-data truncate grid">
                                    <span>
                                        <div className="colHeader">{getLabel("provider_designations_list_start_date")}</div>
                                        <div>{formatDate(designation.startDate)}</div>
                                    </span>
                                    <span>
                                        <div className="colHeader">{getLabel("provider_designations_list_end_date")}</div>
                                        <div>{!!designation.endDate ? formatDate(designation.endDate) : ''}</div>
                                    </span>
                                    <span>
                                        <div className="colHeader">{getLabel("provider_designations_list_designating_user")}</div>
                                        <div>{designation.designatingUser!.name}</div>
                                    </span>
                                    <span>
                                        <div className="colHeader">{getLabel("provider_designations_list_source")}</div>
                                        <div>{designation.designationSource.name}</div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ListItem></Paper>)}
                </List>
                {totalResults === 0 && <p className="paging">{getLabel("provider_designations_result_none")}</p>}
                {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
            </CardContent>
        </Card>
    );
};