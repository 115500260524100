import React from 'react';
import { getLabel } from '../common/label/Label.library';
import { Formik } from 'formik';
import { Button, CardContent, Card } from '@mui/material';
import { AppState } from '../../store/configureStore';
import { TextInput } from '../common/text.input/TextInput';
import { CheckboxInput } from '../../components/common/checkbox.input/CheckboxInput';
import { useDispatch, useSelector } from 'react-redux';
import { subgroupSearchValues } from '../../reducers/rootReducer';
import { ClearSubgroupSearchAction, SetSubgroupSearchAction, SubgroupSearchValues } from '../../actions/subgroupSearchAction'
import { StatesDropdown } from '../../components/common/states.dropdown/StatesDropdown';
import './GroupAttributes.css';

interface Props {
  getSubgroups: (values: SubgroupSearchValues, page: number) => Promise<void>;
}

export const SubgroupSearch: React.FC<Props> = ({ getSubgroups }) => {

  const searchValues = useSelector<AppState, SubgroupSearchValues>(subgroupSearchValues);
  const dispatch = useDispatch();

  async function performSearch(values: SubgroupSearchValues) {
    dispatch(SetSubgroupSearchAction(values));
    await getSubgroups(values, 1); //TODO: can't we just `useEffect` (in parent component) on the "search values" change triggered by the action above, eliminate this callback property?
  }

  async function clearSubgroupSearchFilters() {
    dispatch(ClearSubgroupSearchAction());
    await getSubgroups({ name: '', city: '', state: '', includeInactive: false }, 1);
  }

  return (
    <Card>
      <CardContent>
        <Formik enableReinitialize={true}
          initialValues={searchValues}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            performSearch(values).finally(() => {
              actions.setTouched({});
              actions.setSubmitting(false);
            });
          }}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className="search-filter-fields">
                <TextInput name="name" label="group_filter_name_label" fullwidth={false} />
                <TextInput name="city" label="group_filter_city_label" fullwidth={false} />
                <StatesDropdown name="state" label="group_filter_state_label" />
              </div>
              <div className="search-filter-buttons">
                <CheckboxInput name="includeInactive" label="group_filter_include_inactive" />
                <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSubgroupSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('perform_search_button_label')}</Button>
              </div>
            </form>)}
        </Formik>
      </CardContent>
    </Card>
  );
};