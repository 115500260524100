import { Button, Card, CardContent } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SetUserMessageSuccessAction } from '../../../actions/userMessageAction';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { Permissions } from '../../../constants/Permissions';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { setUserPreferenceValue, UserPreferenceIds } from '../../../constants/UserPreferenceIds';
import { User, UserPreference } from '../../../interfaces/ApiInterfaces';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { getUserId, hasPermissions } from '../../../services/auth/auth';
import { CheckboxInput } from '../../common/checkbox.input/CheckboxInput';
import { getLabel } from '../../common/label/Label.library';

interface Props {
    user: User;
}

export const UserPreferences: React.FC<Props> = ({ user }) => {
    const [preferences, setPreferences] = useState<Array<UserPreference>>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const canUpdatePreferences = (user.id != getUserId() && hasPermissions(Permissions.CAN_MANAGE_ALL_USER_PREFERENCES)) ||
        (user.id === getUserId() && hasPermissions(Permissions.CAN_MANAGE_OWN_PREFERENCES));

    useEffect(() => {        
        getPreferences();
    }, [user.id, dispatch]);

    const getPreferences = async () => {
        const response = await makeJSONGetRequest(createUrl(ApiUrls.GET_USER_PREFERENCES, { userId: user.id }), dispatch, null, false, false);
        setPreferences(response.body.preferences);
    };

    const savePreferences = async (values: any, actions: any) => {
        setUserPreferenceValue(preferences.find(p => p.id === UserPreferenceIds.PRR_EMAILS), values.PrrEmails);
        setUserPreferenceValue(preferences.find(p => p.id === UserPreferenceIds.SUMMARY_EMAILS), values.SummaryEmails);
        const data = {
            UserId: user.id,
            Preferences: preferences
        }
        try {
            const response = await makeJSONPostRequest(createUrl(ApiUrls.UPDATE_USER_PREFERENCES, { userId: user.id }), dispatch, data, true, true);
            if (response.response.ok) {
                navigate(createRoute(ApplicationRoutes.USERS));
                dispatch(SetUserMessageSuccessAction('user_preferences_success_save'));
            }
        } catch (error: any) {
            actions.setTouched({});
            actions.setSubmitting(false);
                
        }
    };

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.USERS));
    };

    return (
        <Card>
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        PrrEmails: preferences.find(p => p.id === UserPreferenceIds.PRR_EMAILS)?.preferenceBooleanValue ? true : false,
                        SummaryEmails: preferences.find(p => p.id === UserPreferenceIds.SUMMARY_EMAILS)?.preferenceBooleanValue ? true : false
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        savePreferences(values, actions).finally(() => {
                            actions.setSubmitting(false);
                        });
                    }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className={!canUpdatePreferences ? 'view-only' : undefined}>
                                <div className="user-preferences">
                                    <CheckboxInput name="PrrEmails" label="user_preferences_prr_label" disabled={!canUpdatePreferences}/>
                                    <CheckboxInput name="SummaryEmails" label="user_preferences_summary_label" disabled={!canUpdatePreferences}/>
                                </div>
                                {canUpdatePreferences &&
                                    <div className="flex">
                                        <span className="flexIndentLeft"></span>
                                        <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>{getLabel('user_preferences_cancel_button_label')}</Button>
                                        <Button className="button" type="submit" variant="contained" color="primary" disabled={!user.active || props.isSubmitting}>{getLabel('user_preferences_submit_button_label')}</Button>
                                    </div>
                                }
                            </div>
                        </form>)}
                </Formik>
            </CardContent>
        </Card>);
}