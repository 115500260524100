import React from 'react';
import { getLabel } from '../common/label/Label.library';
import { Formik } from 'formik';
import { Button, CardContent, Card } from '@mui/material';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { TextInput } from '../common/text.input/TextInput';
import { CheckboxInput } from '../common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import { SetUserMessageErrorAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { StatesDropdown } from '../common/states.dropdown/StatesDropdown';
import './SubgroupAttributes.css';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { TabValue } from '../../constants/TabValue';
import { Group, ACN } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { useActingFor, getActingForDetails } from '../../hooks/useActingFor';
import { TextFieldMaxLengths } from '../../constants/TextFieldMaxLengths';
import { isAllEmpty, isEmpty, isValidEmail } from '../../services/validate/validate';

interface Props {
  groupId: number;
  subgroup: Group;
  acn: ACN;
}

interface SubgroupFormValues {
  Name: string;
  Email: string;
  Active: boolean;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  ZipCode: string;
  Telephone: string;
  Extension: string;
  ExternalId: string;
  Description: string;
}

export const SubgroupInfo: React.FC<Props> = ({groupId, subgroup, acn}) => {
  const acnActingFor = useActingFor();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validate = (values: SubgroupFormValues) => {
    const errors: { [key: string]: string } = {};
    if (isEmpty(values.Name)) {
      errors.Name = getLabel('validation_message_required');
    } else if (/[,'\"|\t]/.test(values.Name)) {
      errors.Name = getLabel('validation_message_special_characters');
    }
    if (!isEmpty(values.Email) && !isValidEmail(values.Email)) {
      errors.Email = getLabel('validation_message_email');
    }
    if (!isEmpty(values.Telephone) && !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i.test(values.Telephone)) {
        errors.Telephone = getLabel('validation_message_telephone');
    }
    if (isEmpty(values.Address1) && !isAllEmpty(values.Address2, values.City, values.State, values.ZipCode)) {
      errors.Address1 = getLabel('validation_message_required');
    }
    if (isEmpty(values.City) && !isAllEmpty(values.Address1, values.Address2, values.State, values.ZipCode)) {
        errors.City = getLabel('validation_message_required');
    }
    if (isEmpty(values.State) && !isAllEmpty(values.Address1, values.Address2, values.City, values.ZipCode)) {
        errors.State = getLabel('validation_message_required');
    }
    if (isEmpty(values.ZipCode) && !isAllEmpty(values.Address1, values.Address2, values.City, values.State)) {
        errors.ZipCode = getLabel('validation_message_required');
    }
    return errors;
  }

    const isFormClean = (values: SubgroupFormValues): boolean => {
    return (values.Name === (subgroup.name ?? '') &&
      values.Email === (subgroup.email ?? '') &&
      values.Active === (subgroup.active ?? true) &&
      values.Address1 === (subgroup.address1 ?? '') &&
      values.Address2 === (subgroup.address2 ?? '') &&
      values.City === (subgroup.city ?? '') &&
      values.State === (subgroup.state ?? '') &&
      values.ZipCode === (subgroup.zipCode ?? '') &&
      values.Telephone === (subgroup.telephone ?? '') &&
      values.Extension === (subgroup.extension ?? '') &&
      values.ExternalId === (subgroup.externalId ?? '') &&
      values.Description === (subgroup.description ?? ''));
  }

  const cancelEdit = () => {
    navigate(createRoute(ApplicationRoutes.EDIT_GROUP, { id: groupId, tab: TabValue.GROUP_SUBGROUP }));
  }

  const saveGroup = async (values: SubgroupFormValues, actions: any) => {
    const data = {
      Name: values.Name,
      Email: values.Email,
      Active: values.Active,
      Address1: values.Address1,
      Address2: values.Address2,
      City: values.City,
      State: values.State,
      ZipCode: values.ZipCode,
      Telephone: values.Telephone,
      Extension: values.Extension,
      ExternalId: values.ExternalId,
      Description: values.Description,
      ParentId: subgroup.parentId,
      AccountableCareNetworkId: acn.id,
      ...getActingForDetails(acnActingFor)
    };
    
    try {
      const response = (subgroup.id === -1) ?
        await makeJSONPostRequest(ApiUrls.CREATE_GROUP, dispatch, data) :
        await makeJSONPostRequest(createUrl(ApiUrls.EDIT_GROUP, { groupId: subgroup.id }), dispatch, data);

      if (response.response.ok) {
        dispatch(SetUserMessageSuccessAction((subgroup.id === -1) ? 'subgroup_success_add_text' : 'subgroup_success_edit_text'));
        navigate(createRoute(ApplicationRoutes.EDIT_GROUP, { id: groupId, tab: TabValue.GROUP_SUBGROUP }));
      }
    } catch (error: any) {
      if (error.status === 401) dispatch(SetUserMessageErrorAction('subgroup_failed_save_unauthorized'));
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  return (
    <Card>
      <CardContent>
        <Formik enableReinitialize={true}
          initialValues={{
            Name: subgroup.name ?? '', 
            Email: subgroup.email ?? '', 
            Active: subgroup.active ?? true, 
            Address1: subgroup.address1 ?? '',
            Address2: subgroup.address2 ?? '',
            City: subgroup.city ?? '', 
            State: subgroup.state ?? '', 
            ZipCode: subgroup.zipCode ?? '', 
            Telephone: subgroup.telephone ?? '', 
            Extension: subgroup.extension ?? '', 
            ExternalId: subgroup.externalId ?? '',
            Description: subgroup.description ?? ''
          }}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            saveGroup(values, actions);
          }}>
          {(props) => (
            <form className="edit-subgroup-attributes" onSubmit={props.handleSubmit}>
              <div className="edit-subgroup-attributes-fields">
                <div>
                  <TextInput name="Name" label="subgroup_attributes_name_label" fullwidth={false} />
                  <TextInput name="Email" label="subgroup_attributes_email_label" fullwidth={false} />
                  <span className="half-width grid">
                    <TextInput name="Telephone" label="subgroup_attributes_telephone_label" fullwidth={false} />
                    <TextInput name="Extension" label="subgroup_attributes_extension_label" type="digit" maxLength={TextFieldMaxLengths.PHONE_EXTENSION_MAX_LENGTH} fullwidth={false} />
                  </span>
                </div>
                <div>
                  <TextInput name="Address1" label="subgroup_attributes_address1_label" fullwidth={false} />
                  <TextInput name="Address2" label="subgroup_attributes_address2_label" fullwidth={false} />
                  <TextInput name="City" label="subgroup_attributes_city_label" fullwidth={false} />
                  <span className="half-width grid">
                    <StatesDropdown name="State" label="subgroup_attributes_state_label" halfWidth={true} />
                    <TextInput name="ZipCode" label="subgroup_attributes_zip_code_label" fullwidth={false} />
                  </span>
                </div>
                <div>
                  <div className="acnLabel"><div className="colHeader">{getLabel("subgroup_attributes_acn_label")}</div><div>{acn.acnName}</div></div>
                  <div className="groupLabel"><div className="colHeader">{getLabel("subgroup_attributes_parent_label")}</div><div>{subgroup.parentGroup?.name}</div></div>
                  <TextInput name="Description" label="subgroup_attributes_description_label" fullwidth={false} multiline={true} maxLength={null} />
                </div>
                <div>
                  <TextInput name="ExternalId" label="subgroup_attributes_esid_label" fullwidth={false} />            
                </div>
              </div>
              <div className="edit-subgroup-attributes-buttons">
                <CheckboxInput name="Active" label="group_attributes_active_label" />
                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>{getLabel('group_attributes_cancel_button_label')}</Button>
                <Button className="button" type="submit" variant="contained" color="primary" disabled={isFormClean(props.values) || props.isSubmitting || (acnActingFor.id !== acn.id)}>{getLabel('group_attributes_submit_button_label')}</Button>
              </div>
            </form>)}
        </Formik>
      </CardContent>
    </Card>
  )
}
