import { AppBar, Card, Container, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLabel } from '../../../components/common/label/Label.library';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { ProviderAffiliations } from '../../../components/providers/provider.affiliations/ProviderAffiliations';
import { ViewProviderAttributes } from '../../../components/providers/provider.info.view/ViewProviderAttributes';
import { ProviderAttributes } from '../../../components/providers/ProviderAttributes';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { InfoTitles } from '../../../constants/InfoTitles';
import { Permissions } from '../../../constants/Permissions';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { TabValue } from '../../../constants/TabValue';
import { useActingFor } from '../../../hooks/useActingFor';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../../services/ajax/ajax';
import { hasPermissions } from '../../../services/auth/auth';
import './EditProvider.css';
import { PageHeading } from '../../../components/common/page.heading/PageHeading';
import { ProviderDesignations } from '../../../components/providers/provider.designations/ProviderDesignations';
import { useNavigate, useParams } from 'react-router-dom';
import { ProviderDetails } from '../../../interfaces/ApiInterfaces';

export const EditProvider: React.FC<any> = (props) => {
    const [tabValue, setTabValue] = useState(0);
    const [provider, updateProvider] = useState<ProviderDetails>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const canManageProviders = hasPermissions(Permissions.CAN_MANAGE_PROVIDERS);
    const canUpdateProviderOverrides = hasPermissions(Permissions.CAN_UPDATE_PROVIDER_RECORD);
    const acnActingFor = useActingFor();
    const params = useParams();

    function getAddEditViewLabel() {
        const providerFirstName = provider!.providerOverride?.pcpFirstName ?? provider!.pcpFirstName;
        const providerLastName = provider!.providerOverride?.pcpLastName ?? provider!.pcpLastName;
        var label = getLabel(canManageProviders
            ? 'provider_edit_page_heading'
            : 'provider_view_page_heading', { displayName: `${providerFirstName} ${providerLastName}` });
        return label;
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_PROVIDER;
        }
        else if (tabValue === 1) {
            return InfoTitles.PROVIDER_AFFILIATIONS;
        }
        else {
            return InfoTitles.PROVIDER_DESIGNATIONS;
        } 
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: params.id, tab: TabValue.PROVIDER_ATTRIBUTES }))
        }
        else if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: params.id, tab: TabValue.PROVIDER_AFFILIATIONS }))
        }
        else if (value === 2) {
            navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: params.id, tab: TabValue.PROVIDER_DESIGNATIONS }))
        }
        setTabValue(value);
    }

    useEffect(() => {
        if (params.tab === TabValue.PROVIDER_ATTRIBUTES) {
            setTabValue(0);
        }
        else if (params.tab === TabValue.PROVIDER_AFFILIATIONS) {
            setTabValue(1);
        }
        else if (params.tab === TabValue.PROVIDER_DESIGNATIONS) {
            setTabValue(2);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_PROVIDER, { id: params.id, tab: TabValue.PROVIDER_ATTRIBUTES }));
            setTabValue(0);
        }
    }, [params.id, params.tab])

    useEffect(() => {
        document.title = `${getLabel('app_name')} ${getLabel('title_edit_provider')}`;
    }, []);

    useEffect(() => {
        if (acnActingFor.id !== -1) {
            const getProvider = async () => {
                const providerResponse = await makeJSONGetRequest(createUrl(ApiUrls.GET_PROVIDER_WITH_OVERRIDE, { providerId: params.id, acnId: acnActingFor.id }), dispatch, null, false, false);
                var pro: ProviderDetails = providerResponse.body;
                const id = [pro.id];
                const affiliations = await makeJSONPostRequest(createUrl(ApiUrls.ACN_PROVIDER_AFFILIATION_STATUS, { acnId: acnActingFor.id }), dispatch, id, false, false);
                pro.affiliationStatus = affiliations.body.find((e: any) => e.providerId === pro.id).status;
    
                const releaseRequestCount = await makeJSONPostRequest(createUrl(ApiUrls.ACN_PROVIDER_ACTIVE_RELEASE_REQUEST_COUNT, { acnId: acnActingFor.id, providerId: pro.id }), dispatch, null, false, false);
                pro.hasActiveReleaseRequests = releaseRequestCount.body > 0;
                updateProvider(pro);
            };
    
            getProvider();
        }
    }, [dispatch, params.id, acnActingFor.id]);

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="edit-provider-container">
                {provider !== undefined &&
                    <>
                        <h2><PageHeading to={createRoute(ApplicationRoutes.PROVIDERS)} parentHeading={getLabel('providers_page_heading')}>{getAddEditViewLabel()}</PageHeading></h2>
                        <Card className="edit-provider-panel">
                            <AppBar position="static" color="default">
                                <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} indicatorColor="primary" textColor="primary">
                                    <Tab value={0} label={getLabel('tab_label_attributes')} />
                                    <Tab value={1} label={getLabel('tab_label_affiliations')} />
                                    <Tab value={2} label={getLabel('tab_label_designations')} />
                                </Tabs>
                            </AppBar>
                            {tabValue === 0 && (canUpdateProviderOverrides
                                                ? (<ProviderAttributes provider={provider} />)
                                                : (<ViewProviderAttributes provider={provider} />))}
                            {tabValue === 1 && (<ProviderAffiliations provider={provider} setProvider={updateProvider} />)}
                            {tabValue === 2 && (<ProviderDesignations provider={provider} />)}
                        </Card>
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};
