import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { AppBar, Tabs, Tab, Container, Card, Button, FormControl, RadioGroup, FormControlLabel, Radio, InputLabel, Select, Checkbox, SelectChangeEvent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CycleInfo } from '../../components/cycles/CycleAttributes';
import { CyclePrograms } from '../../components/cycles/CyclePrograms';
import { CycleAttestation } from '../../components/cycles/CycleAttestation';
import { ViewCycleInfo } from '../../components/cycles/ViewCycleAttributes';
import './Cycles.css';
import { getLabel } from '../../components/common/label/Label.library';
import { hasPermissions, getUserAcnName } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { makeJSONGetRequest, makePostBlobRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import moment from 'moment-timezone';
import { DateTime } from '../../constants/DateTime';
import { TabValue } from '../../constants/TabValue';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { Cycle } from '../../interfaces/ApiInterfaces';
import { OutputFiles } from '../../constants/OutputFiles';
import { useActingFor } from '../../hooks/useActingFor';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { InfoTitles } from '../../constants/InfoTitles';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { useNavigate, useParams } from 'react-router-dom';

export const EditCycle: React.FC<any> = (props) => {
    const acnActingFor = useActingFor();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const params = useParams();
    const cycleId = +params.id!;
    const [cycle, setCycle] = useState<Cycle>();
    const canDownloadSnapshot = hasPermissions(Permissions.CAN_VIEW_ALL_CYCLE_PARTICIPATION_DATA) || hasPermissions(Permissions.CAN_VIEW_CLIENTS_CYCLE_PARTICIPATION_DATA) || hasPermissions(Permissions.CAN_VIEW_ACNS_CYCLE_PARTICIPATION_DATA);
    const [snapshotRadio, setSnapshotRadio] = useState<number>(getUserAcnName() ? 1 : 0);
    const [snapshotFormat, setSnapshotFormat] = useState<number>(0);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [includeUnattested, setIncludeUnattested] = useState<boolean>(true);

    useEffect(() => {
        document.title = cycleId === -1
            ? `${getLabel('app_name')} ${getLabel('title_add_cycle')}`
            : `${getLabel('app_name')} ${getLabel('title_edit_cycle')}`;
    }, []);

    useEffect(() => {
        getCycle();
    }, [cycleId, dispatch]);

    const getCycle = async () => {
        if (cycleId !== -1) {
            makeJSONGetRequest(createUrl(ApiUrls.GET_CYCLE, { cycleId }), dispatch, null, false, false).then((cycleResponse) => {
                var cycle = cycleResponse.body;
                setCycle({
                    id: cycle.id,
                    name: cycle.name,
                    startAt: moment(cycle.startAt).format(DateTime.DATE),
                    endAt: moment(cycle.endAt).format(DateTime.DATE),
                    snapshotAt: moment(cycle.snapshotAt).format(DateTime.DATE),
                    isOpen: cycle.isOpen,
                    locked: cycle.locked,
                    dedicatedAcnId: cycle.dedicatedACNId
                });
            });
        }
        else {
            setCycle({ id: -1, name: '', startAt: '', endAt: '', snapshotAt: '', isOpen: true, locked: false, dedicatedAcnId: undefined });
        }
    }

    useEffect(() => {
        if (params.tab === TabValue.CYCLE_ATTRIBUTES) {
            setTabValue(0);
        }
        else if (params.tab === TabValue.CYCLE_PROGRAMS && cycleId !== -1) {
            setTabValue(1);
        }
        else if (params.tab === TabValue.CYCLE_ATTESTATION && cycleId !== -1) {
            setTabValue(2);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycleId, tab: TabValue.CYCLE_ATTRIBUTES }))
            setTabValue(0);
        }
    }, [cycleId, params.tab]);

    const downloadSnapshot = async () => {
        var outputFile = OutputFiles.CYCLE_SNAPSHOT_BASE;
        outputFile = includeUnattested ? outputFile.concat(OutputFiles.CYCLE_SNAPSHOT_UNATTESTED) : outputFile;
        switch (snapshotFormat) {
            case 0:
                outputFile = outputFile.concat(OutputFiles.CYCLE_SNAPSHOT_CSV);
                break;
            case 1:
                outputFile = outputFile.concat(OutputFiles.CYCLE_SNAPSHOT_PIPE_DELIMITED);
                break;
            default:
                outputFile = outputFile.concat(OutputFiles.CYCLE_SNAPSHOT_CSV);
                break;
        }
        const data = {
            cycleId: cycleId,
            acnId: snapshotRadio === 0 ? -1 : acnActingFor.id,
            format: snapshotFormat,
            includeUnattested: includeUnattested
        }
        setDownloading(true);
        if (hasPermissions(Permissions.CAN_VIEW_ALL_CYCLE_PARTICIPATION_DATA)) {
            await makePostBlobRequest(ApiUrls.DOWNLOAD_CYCLE_PARTICIPATION_AS_ADMIN, dispatch, outputFile, data, true, true);
        } else if (hasPermissions(Permissions.CAN_VIEW_CLIENTS_CYCLE_PARTICIPATION_DATA)) {
            await makePostBlobRequest(ApiUrls.DOWNLOAD_CYCLE_PARTICIPATION_AS_CLIENT, dispatch, outputFile, data, true, true);
        } else {
            await makePostBlobRequest(ApiUrls.DOWNLOAD_CYCLE_PARTICIPATION_AS_ACN_USER, dispatch, outputFile, data, true, true);
        }
        dispatch(SetUserMessageSuccessAction('cycle_snapshot_prepared'));
        setDownloading(false);
    };

    function getAddEditViewLabel() {
        var label;
        if (cycleId === -1) {
            label = getLabel('cycle_add_page_heading');
        } else {
            label = getLabel(hasPermissions(Permissions.CAN_UPDATE_CYCLE)
                ? 'cycle_edit_page_heading'
                : 'cycle_view_page_heading', { name: cycle!.name });
        }
        return label;
    }

    const getInfoTitle = () => {
        if (tabValue === 0) {
            return InfoTitles.UPDATE_CYCLE;
        } else if (tabValue === 1) {
            return InfoTitles.CYCLE_PROGRAMS;
        } else {
            return InfoTitles.CYCLE_ATTESTATION;
        }
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycleId, tab: TabValue.CYCLE_ATTRIBUTES }))
        }
        else if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycleId, tab: TabValue.CYCLE_PROGRAMS }))
        }
        else if (value === 2) {
            navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycleId, tab: TabValue.CYCLE_ATTESTATION }))
        }
        setTabValue(value);
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()}>
            <Container maxWidth={false} className="cycle-attributes-container">
                {!!cycle &&
                    <>
                        <h2><PageHeading to={createRoute(ApplicationRoutes.CYCLES)} parentHeading={getLabel('cycle_page_heading')}>{getAddEditViewLabel()}</PageHeading></h2>
                        <Card className="user-attributes-panel">
                            <AppBar position="static" color="default">
                                <Tabs value={tabValue} onChange={(event, value) => handleTabChange(value)} aria-label="simple tabs example" indicatorColor="primary"
                                    textColor="primary">
                                    <Tab label={getLabel('tab_label_attributes')} value={0} />
                                    <Tab label={getLabel('tab_label_programs')} value={1} disabled={cycleId === -1 || !hasPermissions(Permissions.CAN_VIEW_PROGRAMS)} />
                                    <Tab label={getLabel('tab_label_attestation')} value={2} disabled={cycleId === -1} />
                                </Tabs>
                            </AppBar>
                        {tabValue === 0 && (hasPermissions(Permissions.CAN_UPDATE_CYCLE) ? (<CycleInfo cycle={cycle} getCycle={getCycle}/>) : (<ViewCycleInfo cycle={cycle}/>))}
                            {tabValue === 1 && <CyclePrograms cycle={cycle} />}
                            {tabValue === 2 && <CycleAttestation cycle={cycle} />}
                    </Card>
                    {cycleId !== -1 && <>
                        <h2 className="cycle-snapshot-label">{getLabel('cycle_snapshot_label')}</h2>
                        <Card className="snapshotFlexPadding grid">
                            <FormControl variant="outlined" className="formatDropdown" size="small">
                                <InputLabel id="snapshotFormatLabel" htmlFor="SnapshotFormat">{getLabel("cycle_snapshot_format")}</InputLabel>
                                <Select native labelId="snapshotFormatLabel" label={getLabel("cycle_snapshot_format")}
                                    value={snapshotFormat} onChange={(event: SelectChangeEvent<number>) => { setSnapshotFormat(+event.target.value) }}
                                    id="SnapshotFormat" name="SnapshotFormat">
                                    <option key={0} value={0}>{getLabel("cycle_snapshot_format_csv")}</option>
                                    {(hasPermissions(Permissions.CAN_EXPORT_PIPE_DELIMITED_SNAPSHOT)) && <option key={1} value={1}>{getLabel("cycle_snapshot_format_pipe")}</option>}
                                </Select>
                            </FormControl>
                            <FormControl className="snapshotRadio">
                                <RadioGroup value={snapshotRadio} onChange={(event, value) => setSnapshotRadio(+value)}>
                                    {!getUserAcnName() && <FormControlLabel value={0} control={<Radio color="primary" />} label={getLabel("cycle_snapshot_radio_all")} />}
                                    <FormControlLabel value={1} control={<Radio color="primary" />} label={getLabel("cycle_snapshot_radio_selected")} />
                                </RadioGroup>
                            </FormControl>
                            <FormControlLabel className="cycleSnapshotIncludeUnattested" control={<Checkbox color="primary" checked={includeUnattested} onChange={(event, value) => setIncludeUnattested(value)} name="includeUnattested" />} label={getLabel("cycle_snapshot_include_unattested")} />
                            {canDownloadSnapshot && <Button id="downloadSnapshot" className="green-button" type="button" variant="contained" color="primary" onClick={downloadSnapshot} disabled={downloading}>{getLabel('edit_cycle_download_snapshot_label')}</Button>}
                        </Card>
                    </>
                    }
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};