import React, { useState } from 'react';
import { getLabel } from '../common/label/Label.library';
import { Formik, FormikTouched } from 'formik';
import { Button, CardContent, Card } from '@mui/material';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { TextInput } from '../common/text.input/TextInput';
import { CheckboxInput } from '../common/checkbox.input/CheckboxInput';
import { useDispatch } from 'react-redux';
import './ProductAttributes.css';
import { ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { Product } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { DialogModal } from '../common/dialog.modal/DialogModal';
import { SetSuccessMessageAction } from '../../actions/successMessageAction';

interface Props {
    product: Product
}

export const ProductAttributes: React.FC<Props> = ({ product }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [submitOpenLeave, setSubmitOpenLeave] = useState(false);
    const [newProduct, setNewProduct] = useState<Product>();

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};

        if (!values.Name.trim()) {
            errors.Name = getLabel('validation_message_required');
        }
        if (!values.ExportName.trim()) {
            errors.ExportName = getLabel('validation_message_required');
        } else if (/[,'\"|\t]/.test(values.ExportName)) {
            errors.ExportName = getLabel('validation_message_special_characters');
        }
        if (!values.RosterImportName.trim()) {
            errors.RosterImportName = getLabel('validation_message_required');
        }
        if (!values.ProviderImportName.trim()) {
            errors.ProviderImportName = getLabel('validation_message_required');
        }
        return errors;
    }

    const isFormClean = (touched: FormikTouched<{ Name: any; ExportName: any; RosterImportName: any; Active: any; ProviderImportName: any; }>) => {
        return (touched.Name === undefined &&
            touched.ExportName === undefined &&
            touched.RosterImportName === undefined &&
            touched.Active === undefined &&
            touched.ProviderImportName === undefined);
    }

    const cancelEdit = () => {
        navigate(ApplicationRoutes.PRODUCTS);
    }

    const handleSubmit = async (values: any, actions: any) => {
        try {
            setNewProduct({ id: product.id, name: values.Name, exportName: values.ExportName, rosterImportName: values.RosterImportName, active: values.Active, providerImportName: values.ProviderImportName })
            if (values.Active != product.active || product.id === -1) {
                values.Active ? await saveProduct(values) : setSubmitOpenLeave(true);
            }
            else {
                await saveProduct(values);
            }
        } finally {
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    const saveProduct = async (values: any = null) => {
        const data = {
            Id: product.id,
            Name: values ? values.Name : newProduct?.name,
            ExportName: values ? values.ExportName : newProduct?.exportName,
            RosterImportName: values ? values.RosterImportName : newProduct?.rosterImportName,
            Active: values ? values.Active : newProduct?.active,
            ProviderImportName: values ? values.ProviderImportName : newProduct?.providerImportName
        };
        (product.id === -1) ?
            await makeJSONPostRequest(ApiUrls.CREATE_PRODUCT, dispatch, data) :
            await makeJSONPostRequest(createUrl(ApiUrls.EDIT_PRODUCT, { productId: product.id }), dispatch, data);
        dispatch(SetSuccessMessageAction((product.id === -1) ? 'product_success_add_text' : 'product_success_edit_text'));
        navigate(ApplicationRoutes.PRODUCTS);
    }

    const completeSubmit = (async () => {
        setSubmitOpenLeave(false);
        await saveProduct(null);
    })

    const cancelSubmit = (() => {
        setSubmitOpenLeave(false);
    })

    return (
        <Card>
            <DialogModal id="CascadeLeave" open={submitOpenLeave} labelLeft={getLabel('product_cascade_cancel_button')} labelRight={getLabel('product_cascade_remove_button')} onClickLeft={cancelSubmit} onClickRight={completeSubmit}
                title={getLabel('product_cascade_remove_dialog_message')}></DialogModal>
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        Name: product.name ?? '',
                        ExportName: product.exportName ?? '',
                        RosterImportName: product.rosterImportName ?? '',
                        Active: product.active ?? true,
                        ProviderImportName: product.providerImportName ?? ''
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        handleSubmit(values, actions);
                    }}>
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className="grid">
                                <TextInput name="Name" label="product_attributes_name_label" fullwidth={false} />
                                <TextInput name="ExportName" label="product_attributes_export_name_label" fullwidth={false} />
                                <TextInput name="RosterImportName" label="product_attributes_roster_import_name_label" fullwidth={false} />
                                <TextInput name="ProviderImportName" label="product_attributes_provider_import_name_label" fullwidth={false} />
                            </div>
                            <div className="flex">
                                <span className="flexIndentLeft"></span>
                                <CheckboxInput name="Active" label="product_attributes_active_label" />
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>{getLabel('product_attributes_cancel_button_label')}</Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={isFormClean(props.touched) || props.isSubmitting}>{getLabel('product_attributes_submit_button_label')}</Button>
                            </div>
                        </form>)}
                </Formik>
            </CardContent>
        </Card>
    )
}
