import React, { useEffect } from 'react';
import './Login.css';
import { Container, Card, CardHeader, CardContent, Button } from '@mui/material';
import { getLabel } from '../../components/common/label/Label.library';
import { UnauthenticatedLayout } from '../../components/layouts/unauthenticated.layout/UnauthenticatedLayout';
import { TextInput } from '../../components/common/text.input/TextInput';
import { Formik } from 'formik';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { setPermissions, isAuthenticated, getAuthenticatedHomePage, setRoles, setUserId, setUserName, setUserAcnId, setUserAcnName, setIsViewOnly } from '../../services/auth/auth';
import { getUserPermissions, getUserRoles } from './Login.library';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { loginRedirectPathname } from '../../reducers/rootReducer';
import { AppState } from '../../store/configureStore';
import { ClearLoginRedirectAction } from '../../actions/loginRedirectAction';
import { SetLogOutAction } from '../../actions/authAction';
import { useLocation, useNavigate } from 'react-router-dom';

export const Login: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const redirectPathname = useSelector<AppState, string>(loginRedirectPathname);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${getLabel('app_name')} ${getLabel('title_login')}`
  }, [])
  
  async function login(values: any) {
    const data = {
      Email: values.username,
      Password: values.password,
    };

    const response = await makeJSONPostRequest(ApiUrls.LOGIN, dispatch, data);
    dispatch(SetLogOutAction(false));
    const json = await response.body;
    setUserId(json.id);
    setUserName(json.name);
    // The only scenario that doesn't have the acting for selector is for a ACN role user with a single ACN associated
    if (!!json.accountableCareNetworks && json.accountableCareNetworks.length === 1) {
        setUserAcnId(json.accountableCareNetworks[0].id);
        setUserAcnName(json.accountableCareNetworks[0].acnName);
    }
    setRoles(getUserRoles(json.roles));
    setPermissions(getUserPermissions(json.roles));
    setIsViewOnly(String(json.isViewOnly));
    navigate(!!redirectPathname && redirectPathname !== ApplicationRoutes.LOGIN 
      ? redirectPathname 
      : getAuthenticatedHomePage()
    );
    dispatch(ClearLoginRedirectAction());
  };

  const validate = (values: any) => {
    const errors: { [key: string]: string } = {};
    // Validate username
    if (!values.username) {
      errors.username = getLabel('validation_message_required');
    }
    // Validate password
    if (!values.password) {
      errors.password = getLabel('validation_message_required');
    }
    return errors;
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(getAuthenticatedHomePage(), {replace: true});
    }
  }, []);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if(queryParams.passwordSet){
      dispatch(SetUserMessageSuccessAction('set_password_success_text'));
    }
  }, [location.search, dispatch]);

  const resetPassword = () => {
    navigate(ApplicationRoutes.RESET_PASSWORD);
  };

  return (
    <UnauthenticatedLayout {...props}>
      <Formik
        initialValues={{ username: '', password: '' }}
        validate={validate}
        validateOnBlur={false}
        onSubmit={(values, actions) => {
          login(values).finally(() => {
              actions.setSubmitting(false);
          });
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Container maxWidth={false} className="login-container">
              <Card className="login-panel">
                <CardHeader title={getLabel('login_heading')} />
                <CardContent className="login-card-content">
                  <TextInput name="username" label="login_username_label" fullwidth={true}/>
                  <TextInput
                    name="password"
                    label="login_password_label"
                    type="password"
                    fullwidth={true}
                  />
                  <Button
                    className="submit-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!(props.dirty && props.isValid) || props.isSubmitting}
                  >
                    {getLabel('login_submit_button_label')}
                  </Button>
                </CardContent>
              </Card>
              <Button id="toResetPassword" className="link" color="primary" onClick={resetPassword}>{getLabel('reset_password_label')}</Button>
            </Container>
          </form>
        )}
      </Formik>
    </UnauthenticatedLayout>
  );
};
