export class ApiUrls {
    public static readonly GET_APP_VERSION = 'api/Management/Version';
    public static readonly GET_APP_FEATURE_FLAGS = 'api/Management/FeatureFlags';
    public static readonly GET_APP_INACTIVITY_TIMEOUT = 'api/Management/InactivityTimeout';
    public static readonly GET_ENVIRONMENT_INDICATOR = 'api/Management/EnvironmentIndicator';
    public static readonly GET_APP_UPLOAD_RESTRICTIONS = 'api/Management/UploadRestrictions';

    public static readonly LOGIN = 'api/User/Login';
    public static readonly LOGOUT = 'api/User/Logout';
    public static readonly SEND_FORGOT_PASSWORD_EMAIL = 'api/User/ForgotPassword';
    public static readonly SEND_RESET_PASSWORD_EMAIL = 'api/User/RequestPasswordReset';
    public static readonly PASSWORD_RESET = 'api/User/PasswordReset';
    public static readonly GET_USERS = 'api/User/GetUsers';
    public static readonly GET_ROLES = 'api/User/GetRoles';
    public static readonly GET_USER = 'api/User/:userId';
    public static readonly EDIT_USER = 'api/User/:userId';
    public static readonly REGISTER_USER = 'api/User/RegisterUser';
    public static readonly GET_USER_PREFERENCES = 'api/User/:userId/GetUserPreferences';
    public static readonly UPDATE_USER_PREFERENCES = 'api/User/:userId/UpdateUserPreferences';
    public static readonly CREATE_USER_ACN_ASSOCIATION = 'api/User/:userId/CreateUserACNAssociation/:acnId';
    public static readonly REMOVE_USER_ACN_ASSOCIATION = 'api/User/:userId/RemoveUserACNAssociation/:acnId';

    public static readonly GET_ALL_ACNS = 'api/AccountableCareNetwork/GetAllACNs';
    public static readonly GET_MY_ACNS = 'api/AccountableCareNetwork/GetMyACNs'; //TODO: merge with the above
    public static readonly GET_ACNS = 'api/AccountableCareNetwork/GetAccountableCareNetworks';
    public static readonly GET_USER_ACNS = 'api/AccountableCareNetwork/GetUserAccountableCareNetworks'; //TODO: move to User controller? or merge with above
    public static readonly GET_PROGRAM_ACNS = 'api/AccountableCareNetwork/FindByProgram'; //TODO: move to Program controller?
    public static readonly GET_ACCOUNTABLE_CARE_NETWORK = 'api/AccountableCareNetwork/:acnId';
    public static readonly EDIT_ACCOUNTABLE_CARE_NETWORK = 'api/AccountableCareNetwork/:acnId';
    public static readonly CREATE_ACCOUNTABLE_CARE_NETWORK = 'api/AccountableCareNetwork/Create';
    public static readonly IMPORT_ACNS = 'api/AccountableCareNetwork/ImportAccountableCareNetworks'
    public static readonly GET_IMPORT_ACNS_RESULTS = 'api/AccountableCareNetwork/GetACNImportResults';
    public static readonly GET_IMPORT_ACNS_RESULT = 'api/AccountableCareNetwork/GetACNImportResult/:importId';
    public static readonly ADD_PROGRAM_AND_PRODUCT_TO_ACN = 'api/AccountableCareNetwork/AddProgramAndProduct';
    public static readonly REMOVE_PROGRAM_AND_PRODUCT_FROM_ACN = 'api/AccountableCareNetwork/RemoveProgramAndProduct';
    public static readonly ACN_AFFILIATIONS = 'api/AccountableCareNetwork/GetProviders';
    public static readonly ACN_END_AFFILIATION = 'api/AccountableCareNetwork/EndAffiliation';
    public static readonly ACN_CREATE_NEW_AFFILIATION = 'api/AccountableCareNetwork/NewAffiliation';
    public static readonly ACN_AFFILIATION_STATUS = 'api/AccountableCareNetwork/AffiliationStatus/:acnId';
    public static readonly ACN_PROVIDER_AFFILIATION_STATUS = 'api/AccountableCareNetwork/ProviderAffiliationStatus/:acnId';
    public static readonly ACN_PROVIDER_ACTIVE_RELEASE_REQUEST_COUNT = 'api/AccountableCareNetwork/ProviderActiveReleaseRequestCount/:acnId/:providerId';
    public static readonly ACN_PROVIDERS_ACTIVE_RELEASE_REQUEST_COUNT = 'api/AccountableCareNetwork/ProvidersActiveReleaseRequestCount/:acnId';

    public static readonly GET_PROVIDERS = 'api/Provider/GetProviders';
    public static readonly GET_PROVIDER = 'api/Provider/GetProvider/:providerId/acn/:acnId';
    public static readonly GET_PROVIDER_WITH_OVERRIDE = 'api/Provider/GetProviderAndOverride/:providerId/acn/:acnId';
    public static readonly EDIT_PROVIDER = 'api/Provider/UpdateProviderOverride';
    public static readonly IMPORT_FACILITY_SITES = 'api/Provider/ImportFacilitySites';
    public static readonly GET_IMPORT_FACILITY_SITES_RESULTS = 'api/Provider/GetFacilitySiteImportResults';
    public static readonly GET_IMPORT_FACILITY_SITES_RESULT = 'api/Provider/GetFacilitySiteImportResult/:importId';
    public static readonly IMPORT_PROVIDERS = 'api/Provider/ImportProviders';
    public static readonly GET_IMPORT_PROVIDERS_RESULTS = 'api/Provider/GetProviderImportResults';
    public static readonly GET_IMPORT_PROVIDERS_RESULT = 'api/Provider/GetProviderImportResult/:importId';
    public static readonly DOWNLOAD_IMPORT_ROSTER_SUMMARY = "api/Provider/GetRosterImportSummaryData";
    public static readonly GET_IMPORT_ROSTER_RESULTS = 'api/Provider/GetRosterImportResults';
    public static readonly GET_IMPORT_ROSTER_RESULT = 'api/Provider/GetRosterImportResult/:importId';
    public static readonly PROVIDER_ACTIVE_RELEASE_REQUESTS = 'api/Provider/:providerId/ActiveReleaseRequestStatus';
    public static readonly REQUEST_PROVIDER_RELEASE = 'api/Provider/RequestProviderRelease';
    public static readonly APPROVE_PROVIDER_RELEASE = 'api/Provider/ApproveProviderReleaseRequest';
    public static readonly REJECT_PROVIDER_RELEASE = 'api/Provider/DeclineProviderReleaseRequest';
    public static readonly GET_DESIGNATION_TYPES = 'api/Provider/GetDesignationTypes';
    public static readonly GET_DESIGNATION_SOURCES = 'api/Provider/GetDesignationSources';
    public static readonly GET_DESIGNATIONS = 'api/Provider/GetDesignations';
    public static readonly GET_DESIGNATION = 'api/Provider/GetDesignation/:designationId';
    public static readonly CREATE_DESIGNATION = 'api/Provider/CreateDesignation';
    public static readonly UPDATE_DESIGNATION = 'api/Provider/UpdateDesignation/:designationId';

    public static readonly GET_GROUPS = 'api/Group/GetGroups';
    public static readonly GET_SUBGROUPS = 'api/Group/GetSubgroups';
    public static readonly GET_GROUP = 'api/Group/:groupId';
    public static readonly EDIT_GROUP = 'api/Group/:groupId';
    public static readonly CREATE_GROUP = 'api/Group';
    public static readonly GET_GROUP_PROVIDERS = 'api/Group/GetProvidersByGroup';
    public static readonly GET_SUBGROUP_PROVIDERS = 'api/Group/GetProvidersBySubgroup';
    public static readonly GROUP_END_MEMBERSHIP = 'api/Group/RemoveGroupProvider';
    public static readonly SUBGROUP_ADD_MEMBERSHIP = 'api/Group/AddSubGroupProvider';
    public static readonly SUBGROUP_END_MEMBERSHIP = 'api/Group/RemoveSubGroupProvider';

    public static readonly GET_PROGRAMS = 'api/Program/GetPrograms';
    public static readonly GET_PROGRAMS_AND_PRODUCTS = 'api/Program/GetProgramsAndProducts';
    public static readonly GET_ACN_PROGRAMS_AND_PRODUCTS = 'api/Program/GetACNProgramsAndProducts/:acnId';  //TODO: move to ACN controller?
    public static readonly GET_PROGRAM = 'api/Program/:programId';
    public static readonly EDIT_PROGRAM = 'api/Program/:programId';
    public static readonly CREATE_PROGRAM = 'api/Program';
    public static readonly GET_PROGRAM_CYCLES = 'api/Program/GetCycles';
    public static readonly GET_PROGRAM_RULES = 'api/Program/ReadProgramRules/:programId';
    public static readonly SET_PROGRAM_RULES = 'api/Program/UpdateProgramRules/:programId';
    public static readonly JOIN_UPCOMING_CYCLES = 'api/Program/JoinUpcomingCycles/:programId';
    public static readonly LEAVE_UPCOMING_CYCLES = 'api/Program/LeaveUpcomingCycles/:programId';
    public static readonly HAS_NOT_INCLUDED_CYCLES = 'api/Program/HasNotIncludedCycles/:programId';

    public static readonly GET_ALL_PRODUCTS = 'api/Product';
    public static readonly GET_PRODUCTS = 'api/Product/GetProducts';
    public static readonly GET_PRODUCT = 'api/Product/:productId';
    public static readonly EDIT_PRODUCT = 'api/Product/:productId';
    public static readonly CREATE_PRODUCT = 'api/Product';
    public static readonly GET_PROVIDER_PRODUCTS = 'api/Product/Provider/:providerId';  //TODO: move to Provider controller?
    public static readonly GET_PROGRAM_PRODUCTS = 'api/Product/Program/:programId';  //TODO: move to Program controller?
    public static readonly ADD_PRODUCT_TO_PROGRAM = 'api/Product/AddProgram';
    public static readonly REMOVE_PRODUCT_FROM_PROGRAM = 'api/Product/RemoveProgram';

    public static readonly GET_CYCLES = 'api/Cycle/GetCycles';
    public static readonly GET_LATEST_CYCLE = 'api/Cycle/GetLatestCycle';
    public static readonly GET_NEXT_CYCLE = 'api/Cycle/GetNextCycle/:acnId';
    public static readonly GET_CYCLE = 'api/Cycle/:cycleId';
    public static readonly EDIT_CYCLE = 'api/Cycle/:cycleId';
    public static readonly CREATE_CYCLE = 'api/Cycle';
    public static readonly GET_CYCLE_PROGRAMS = 'api/Cycle/GetPrograms';
    public static readonly CYCLE_ADD_PROGRAM = 'api/Cycle/:cycleId/AddProgram/:programId';
    public static readonly CYCLE_REMOVE_PROGRAM = 'api/Cycle/:cycleId/RemoveProgram/:programId';
    public static readonly GET_CYCLE_PROGRAM_RULES = 'api/Cycle/:cycleId/GetProgramRules';
    public static readonly GET_CYCLE_PARTICIPATION = 'api/Cycle/:cycleId/GetParticipation/:acnId';
    public static readonly DOWNLOAD_CYCLE_PARTICIPATION_AS_ADMIN = "api/Cycle/GetAllCycleParticipationData"; //TODO: merge these 3 into one, determined server-side
    public static readonly DOWNLOAD_CYCLE_PARTICIPATION_AS_CLIENT = "api/Cycle/GetClientsCycleParticipationData";
    public static readonly DOWNLOAD_CYCLE_PARTICIPATION_AS_ACN_USER = "api/Cycle/GetACNsCycleParticipationData";
    public static readonly CONFIRM_CYCLE_PARTICIPATION_ATTESTATION = 'api/Cycle/ConfirmAttestation';
    public static readonly REMOVE_CYCLE_PARTICIPATION_ATTESTATION = 'api/Cycle/RemoveAttestation';

    public static readonly GET_REPORTS = 'api/Report/GetReports';
    public static readonly GET_REPORT_CATEGORIES = 'api/Report/GetReportCategories';
    public static readonly GET_REPORT = 'api/Report/:reportId';

    public static readonly GET_FILES = 'api/BlobStorage/GetFiles';
    public static readonly GET_FILE_OPTIONS = 'api/BlobStorage/GetFileTypeAndStatusOptions/:acnId';
    public static readonly PREPARE_STORAGE_CONTAINER = 'api/BlobStorage/PrepareStorageContainer/:acnId';
    public static readonly UPLOAD_FILE = 'api/BlobStorage/UploadToBlobStorage/:acnId';
    public static readonly DOWNLOAD_FILE = 'api/BlobStorage/GetBlob';
    public static readonly DELETE_FILE = 'api/BlobStorage/DeleteBlob';
    public static readonly CONFIRM_FILE_ATTESTATION = 'api/BlobStorage/AttestBlob';
    public static readonly REMOVE_FILE_ATTESTATION = 'api/BlobStorage/RemoveBlobAttestation';
    public static readonly REJECT_FILE = 'api/BlobStorage/RejectBlob';
    public static readonly GET_FILE_IMPORT_DETAILS = 'api/BlobStorage/GetImportDetails';
    public static readonly GET_FILE_IMPORT_DETAILS_CSV = 'api/BlobStorage/GetImportDetailsCsv';

    public static readonly GET_INFO = 'api/Info/:infoId';
    public static readonly GET_INFO_LIST = 'api/Info/GetInfoList';
    public static readonly GET_INFO_ID_BY_TITLE = 'api/Info/GetInfoIdByTitle';

    public static readonly GET_ACN_NOTIFICATIONS = 'api/Notification/GetACNAlerts/:acnId';
    public static readonly DISMISS_ACN_NOTIFICATION = 'api/Notification/DismissForACN/:notificationId';
    public static readonly DISMISS_RELEASE_REQUEST_NOTIFICATION = 'api/Notification/DismissReleaseRequestsForUser';
}

export function createUrl(url: string, params: { [key: string]: any }): string {
    Object.keys(params).forEach((key) => {
        let value = String(params[key]);
        if (value.includes('/')) // if sth like '../../another/endpoint' comes in one of the params (in a client-side CSRF attack)
            throw "Invalid URL parameter value!";
        url = url.replace(':' + key, value);
    });
    return url;
}