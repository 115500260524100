import { Button, List, Paper, ListItem, Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getLabel } from "../../components/common/label/Label.library";
import { PagingFooter } from "../../components/common/paging/PagingFooter";
import { Group } from "../../interfaces/ApiInterfaces";
import { hasPermissions } from "../../services/auth/auth";
import { Permissions } from '../../constants/Permissions';
import { SubgroupSearch } from "../../components/groups/SubgroupSearch";
import { SearchDefaults } from "../../constants/SearchDefaults";
import { SetUserMessageSuccessAction } from "../../actions/userMessageAction";
import { ApiUrls } from "../../constants/ApiUrls";
import { makeJSONPostRequest } from "../../services/ajax/ajax";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createRoute, ApplicationRoutes } from "../../constants/ApplicationRoutes";
import { TabValue } from "../../constants/TabValue";
import { subgroupSearchValues } from "../../reducers/rootReducer";
import { AppState } from "../../store/configureStore";
import { SubgroupSearchValues } from "../../actions/subgroupSearchAction";
import './Groups.css';

interface Props {
    group: Group;
}

export const Subgroups: React.FC<Props> = ({ group }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [subgroups, setSubgroups] = useState<Group[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);
    const subgroupSearch = useSelector<AppState, SubgroupSearchValues>(subgroupSearchValues);

    useEffect(() => {
        getSubgroups(subgroupSearch, 1);
    }, []);

    const getSubgroups = async (values: SubgroupSearchValues, newPage: number, showSucessMessage: boolean = true, showWorkingMessage: boolean = true) => {
        const data: any = {
            Name: values.name || undefined,
            City: values.city || undefined,
            State: values.state || undefined,
            IncludeInactive: values.includeInactive,
            ParentGroupId: group.id,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "Name",
            OrderByDir: "ASC"
        };
        const response = await makeJSONPostRequest(ApiUrls.GET_SUBGROUPS, dispatch, data, showSucessMessage, showWorkingMessage);

        if (showSucessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('subgroup_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('subgroup_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('subgroup_search_result_many', { totalCount: response.body.totalCount })));
            }
        }

        setSubgroups(response.body.result);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    const selectSubgroup = (subgroupId: number) => {
        var subgroup: any = subgroups.find(subgroup => subgroup.id === subgroupId);
        navigate(createRoute(ApplicationRoutes.EDIT_SUBGROUP, { groupId: group.id, id: subgroup.id, tab: TabValue.SUBGROUP_ATTRIBUTES }));
    };

    const createNewSubgroup = () => {
        navigate(createRoute(ApplicationRoutes.EDIT_SUBGROUP, { groupId: group.id, id: -1, tab: TabValue.SUBGROUP_ATTRIBUTES }));
    };

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getSubgroups(subgroupSearch, page, true, true);
    };

    return (
        <Card>
            <CardContent>
                <SubgroupSearch getSubgroups={getSubgroups} />
                {hasPermissions(Permissions.CAN_CREATE_GROUP) &&
                    <Button id="addButton" variant="contained" color="primary" onClick={createNewSubgroup}>{getLabel("subgroup_list_add")}</Button>
                }
                <div>
                    <List id="resultList">
                        {
                            subgroups.map((subgroup: Group) => <Paper key={group.id}>
                                <ListItem className="row" button onClick={(event) => selectSubgroup(subgroup.id)}>
                                    <div className="truncate">
                                        {subgroup.active && <div className="name">{subgroup.name}</div>}
                                        {!subgroup.active && <div className="name inactive-result">{getLabel('inactive_name', { name: subgroup.name })}</div>}
                                        <div>
                                            <span className="groupCol"><div className="colHeader">{getLabel("subgroup_list_group_header")}</div><div>{group.name}</div></span>
                                            <span className="groupCol"><div className="colHeader">{getLabel("group_list_city_header")}</div><div>{subgroup.city}</div></span>
                                            <span className="groupCol"><div className="colHeader">{getLabel("group_list_state_header")}</div><div>{subgroup.state}</div></span>
                                        </div>
                                    </div>
                                </ListItem>
                            </Paper>)
                        }
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("subgroup_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </CardContent>
        </Card>
    );
};
