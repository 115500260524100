import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLabel } from '../../../components/common/label/Label.library';
import { AuthenticatedLayout } from '../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, Card, CardContent } from '@mui/material';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { formatDatetime } from '../../../services/date/date';
import { createRoute, ApplicationRoutes } from '../../../constants/ApplicationRoutes';
import { InfoTitles } from '../../../constants/InfoTitles';
import { PageHeading } from '../../../components/common/page.heading/PageHeading';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';

export const ACNsImportResult: React.FC<any> = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const importId = +params.id!;
  const [importResult, setImportResult] = useState<any>({});

  useEffect(() => {
    document.title = `${getLabel('app_name')} ${getLabel('title_import_acns_result')}`;
  }, []);

  useEffect(() => {
    makeJSONGetRequest(createUrl(ApiUrls.GET_IMPORT_ACNS_RESULT, { importId }), dispatch, null, false, false)
    .then((importResultResponse) => {
      setImportResult(importResultResponse.body);
    });
  }, [importId, dispatch]);

  return (
    <AuthenticatedLayout {...props} infoTitle={InfoTitles.IMPORT_ACN_RESULT}>
      {Object.keys(importResult).length > 0 && (
        <Container maxWidth={false} className="import-acns-result">
          <div className="import-acn-result-heading import-result-heading">
            <h2>
              <PageHeading to={createRoute(ApplicationRoutes.ACCOUNTABLE_CARE_NETWORKS)} parentHeading={getLabel('acn_page_heading')}>
                <PageHeading to={createRoute(ApplicationRoutes.IMPORT_ACNS)} parentHeading={getLabel('acn_import_page_heading')}>
                  {getLabel('acn_import_result_page_heading', {date: formatDatetime(importResult.importStartedAt)})}
                </PageHeading>
              </PageHeading>
            </h2>
          </div>
          <Card>
            <CardContent>
              <div className="import-acn-result-output import-result-output" dangerouslySetInnerHTML={{ __html: importResult.result ? DOMPurify.sanitize(importResult.result, { ALLOWED_TAGS: ['b', 'br'] }) : ''}}/>
            </CardContent>
          </Card>
        </Container>
      )}
    </AuthenticatedLayout>
  );
};
