import React, { useState, useEffect } from 'react';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { AppBar, Tabs, Tab, Container, Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { SubgroupInfo } from '../../components/groups/SubgroupAttributes';
import { SubgroupProviders } from '../../components/groups/SubgroupProviders';
import './Groups.css';
import { getLabel } from '../../components/common/label/Label.library';
import { makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { TabValue } from '../../constants/TabValue';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { ACN, Group } from '../../interfaces/ApiInterfaces';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { ViewGroupInfo } from '../../components/groups/group.info.view/ViewGroupAttributes';
import { useActingForACN } from '../../hooks/useActingFor';
import { InfoTitles } from '../../constants/InfoTitles';
import { PageHeading } from '../../components/common/page.heading/PageHeading';
import { getAcnFromDropdown, useACNDropdownValues } from '../../hooks/useACNDropdownValues';
import { useNavigate, useParams } from 'react-router-dom';

export const EditSubgroup: React.FC<any> = (props) => {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const groupId = +params.groupId!;
    const subgroupId = +params.id!;
    const acnActingFor = useActingForACN();
    const acnValues = useACNDropdownValues();
    const [subgroup, updateSubgroup] = useState<Group>();
    const [group, updateGroup] = useState<Group>();
    const [subgroupAcn, updateSubgroupAcn] = useState<ACN>();
    const canManageGroups = hasPermissions(Permissions.CAN_CREATE_GROUP) || hasPermissions(Permissions.CAN_UPDATE_GROUP);

    useEffect(() => {
        document.title = subgroupId === -1 ? `${getLabel('app_name')} ${getLabel('title_add_subgroup')}` : `${getLabel('app_name')} ${getLabel('title_edit_subgroup')}`
    }, [])

    useEffect(() => {
        const getGroup = async () => {
            var groupResponse = await makeJSONGetRequest(createUrl(ApiUrls.GET_GROUP, { groupId }), dispatch, null, false, false);
            updateGroup(groupResponse.body);
        }
        getGroup();
    }, []);

    useEffect(() => {
        const getSubgroup = async () => {
            if  (subgroupId !== -1) {
                var subgroupResponse = await makeJSONGetRequest(createUrl(ApiUrls.GET_GROUP, { groupId: subgroupId }), dispatch, null, false, false);
                var subgroupResponseBody = subgroupResponse.body
                updateSubgroup({...subgroupResponseBody, parentGroup: group});
                updateSubgroupAcn(getAcnFromDropdown(acnValues, subgroupResponseBody.accountableCareNetworkId));
            }
            else {
                updateSubgroup({ id: -1, name: '', email: '', active: true, accountableCareNetworkId: acnActingFor.id, parentId: groupId, parentGroup: group, address1: '', address2: '', city: '', state: '', zipCode: '', telephone: '', extension: '', externalId: '', description: ''});
                updateSubgroupAcn(acnActingFor);

            }
        };

        if (acnActingFor.id !== -1) {
            getSubgroup();
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_GROUP, { id: groupId, tab: TabValue.GROUP_SUBGROUP }))
        }
        
    }, [dispatch, subgroupId, acnActingFor.id, groupId, group]);

    useEffect(() => {
        if (params.tab === TabValue.GROUP_ATTRIBUTES) {
            setValue(0);
        }
        else if (params.tab === TabValue.SUBGROUP_PROVIDERS) {
            setValue(1);
        }
        else {
            navigate(createRoute(ApplicationRoutes.EDIT_SUBGROUP, { groupId: groupId, id: subgroupId, tab: TabValue.GROUP_ATTRIBUTES }));
            setValue(0);
        }
    },[subgroupId, acnActingFor.id, params.tab]);

    function getAddEditViewLabel() {
        var label;
        if (subgroupId === -1) {
            label = getLabel('subgroup_add_page_heading');
        } else {
            label = getLabel(canManageGroups
                ? 'subgroup_edit_page_heading'
                : 'subgroup_view_page_heading', { name: subgroup!.name });
        }
        return label;
    }

    function handleTabChange(value: any) {
        if (value === 0) {
            navigate(createRoute(ApplicationRoutes.EDIT_SUBGROUP, { groupId: groupId, id: subgroupId, tab: TabValue.GROUP_ATTRIBUTES }))
        }
        else if (value === 1) {
            navigate(createRoute(ApplicationRoutes.EDIT_SUBGROUP, { groupId: groupId, id: subgroupId, tab: TabValue.SUBGROUP_PROVIDERS }))
        }
        setValue(value);
    }

    const getInfoTitle = () => {
        if (value === 0) {
            return InfoTitles.UPDATE_SUBGROUP;
        } else {
            return InfoTitles.SUBGROUP_PROVIDERS;
        }
    }

    const returnToGroups = () => {
        navigate(createRoute(ApplicationRoutes.GROUPS));
    }

    return (
        <AuthenticatedLayout {...props} infoTitle={getInfoTitle()} actingForOnChange={returnToGroups}>
            <Container maxWidth={false} className="group-attributes-container">
                {!!subgroup?.parentGroup && !!subgroupAcn &&
                    <>
                    <h2>
                        <PageHeading to={createRoute(ApplicationRoutes.GROUPS)} parentHeading={getLabel('group_page_heading')}>
                            <PageHeading to={createRoute(ApplicationRoutes.EDIT_GROUP, { id: groupId, tab: TabValue.GROUP_SUBGROUP })} parentHeading={getLabel('group_edit_page_heading', { name: group?.name })}>
                                {getAddEditViewLabel()}
                            </PageHeading>
                        </PageHeading>
                    </h2>
                        <Card className="group-attributes-panel">
                            <AppBar position="static" color="default">
                                <Tabs value={value} onChange={(event, value) => handleTabChange(value)} indicatorColor="primary"
                                    textColor="primary">
                                <Tab label={getLabel('tab_label_attributes')} value={0} />
                                <Tab label={getLabel('tab_label_providers')} value={1} disabled={subgroupId === -1 || !subgroup.active} />
                                </Tabs>
                            </AppBar>
                            {value === 0 && (canManageGroups ? (<SubgroupInfo groupId = {groupId} subgroup={subgroup} acn={subgroupAcn}/>) : (<ViewGroupInfo group={subgroup} acn={subgroupAcn} />))}
                            {value === 1 && <SubgroupProviders subgroup={subgroup} />}
                        </Card>
                    </>
                }
            </Container>
        </AuthenticatedLayout>
    );
};