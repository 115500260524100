import React, { useState, useEffect } from 'react';
import { getLabel } from '../common/label/Label.library';
import { CardContent, Card, List, ListItem, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import './ProgramAttributes.css';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import { Program } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { TabValue } from '../../constants/TabValue';
import { formatDate } from '../../services/date/date';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { SearchDefaults } from '../../constants/SearchDefaults';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { SetUserMessageSuccessAction, SetUserMessageErrorAction, ClearUserMessageAction } from '../../actions/userMessageAction';
import { Cycle } from '../../interfaces/ApiInterfaces';
import { APIButton } from '../common/button/APIButton';
import { PagingFooter } from '../common/paging/PagingFooter';
import { ClearNextCycleAction } from '../../actions/nextCycleAction';

interface Props {
    program: Program
}

export const ProgramCycles: React.FC<Props> = ({ program }) => {
    const [cycles, setCycles] = useState([]);
    const [programCycles, setProgramCycles] = useState<Cycle[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [page, setPage] = useState(0);

    const selectCycle = (event: React.ChangeEvent<any>, index: number) => {
        if (!event.target.className.includes('Button')) {
            var cycle: any = cycles.find((c: any) => c.id == index);
            navigate(createRoute(ApplicationRoutes.EDIT_CYCLE, { id: cycle.id, tab: TabValue.CYCLE_ATTRIBUTES }))
        }
    }

    const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        await getProgramCycles(page, false);
    };

    async function getProgramCycles(newPage: number, showSucessMessage: boolean = true) {
        const data = {
            id: program.id,
            Skip: (newPage - 1) * SearchDefaults.TAKE,
            Take: SearchDefaults.TAKE,
            OrderBy: "StartAt",
            OrderByDir: "DESC"
        };
        const response = await makeJSONPostRequest(ApiUrls.GET_PROGRAM_CYCLES, dispatch, data, true, true);

        if (showSucessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('cycle_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('cycle_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('cycle_search_result_many',{ totalCount: response.body.totalCount })));
            }
        } else {
            dispatch(ClearUserMessageAction());
        }

        setCycles(response.body.result);
        setProgramCycles(response.body.programCycles);
        setTotalResults(response.body.totalCount);
        setPage(newPage);
    };

    useEffect(() => {
        getProgramCycles(1, true);
    }, [program.id])

    const removeProgramFromCycle = async (cycleId: number) => {
        try {
            const response = await makeJSONPostRequest(createUrl(ApiUrls.CYCLE_REMOVE_PROGRAM, { programId: program.id, cycleId }), dispatch, null);

            if (response.response.ok) {
                await getProgramCycles(page, false);
                dispatch(SetUserMessageSuccessAction('program_cycle_success_remove_text'));
                dispatch(ClearNextCycleAction());
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('program_failed_save_unauthorized'));
        }
    }

    const addProgramToCycle = async (cycleId: number) => {
        try {
            const response = await makeJSONPostRequest(createUrl(ApiUrls.CYCLE_ADD_PROGRAM, { programId: program.id, cycleId }), dispatch, null);

            if (response.response.ok) {
                await getProgramCycles(page, false);
                dispatch(SetUserMessageSuccessAction('program_cycle_success_add_text'));
                dispatch(ClearNextCycleAction());
            }
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('program_failed_save_unauthorized'));
        }
    }

    const showRemove = (cycle: any) => {
        return programCycles.some(c => c.id == cycle.id) && !cycle.locked;
    };
    const showAdd = (cycle: any) => {
        return cycle.isOpen && !programCycles.some(c => c.id == cycle.id) && !cycle.locked;
    };

    return (
        <Card className="programCycles">
            <CardContent>
                <div>
                    <List id="resultList">
                        {cycles.map((cycle: { id: any; name: any; startAt: any; endAt: any; isOpen: any; }) => <Paper key={cycle.id} elevation={2}> <ListItem className="row" button onClick={(event) => selectCycle(event, cycle.id)}>
                            <div className="program-cycle-row truncate">
                                <div>
                                    <div className="name">{cycle.name}</div>
                                    <div className="program-cycle-row-data grid truncate">
                                        <span className="programCycleCol"><div className="colHeader">{getLabel("cycle_list_start_header")}</div><div>{formatDate(cycle.startAt)}</div></span>
                                        <span className="programCycleCol"><div className="colHeader">{getLabel("cycle_list_end_header")}</div><div>{formatDate(cycle.endAt)}</div></span>
                                    </div>
                                </div>
                                <div>
                                    {showRemove(cycle) && <APIButton className="red-button button" type="button" variant="contained" color="primary" onClick={(e: any) => removeProgramFromCycle(cycle.id)} disabled={!hasPermissions(Permissions.CAN_UPDATE_PROGRAM) || !program.active}>{getLabel('edit_program_remove_label')}</APIButton>}
                                    {showAdd(cycle) && <APIButton className="green-button button" type="button" variant="contained" color="primary" onClick={(e: any) => addProgramToCycle(cycle.id)} disabled={!hasPermissions(Permissions.CAN_UPDATE_PROGRAM) || !program.active}>{getLabel('edit_program_add_label')}</APIButton>}
                                    </div>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("cycle_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </CardContent>
        </Card>
    )
}
