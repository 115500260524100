import moment from 'moment-timezone';
import React, { FC, Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { ACTING_FOR_ID, ApplicationRoutes } from './constants/ApplicationRoutes';
import { PrivateRoute } from './components/common/private.route/PrivateRoute';
import { theme } from './theme/theme';
import { ThemeProvider } from "@mui/material";
import { Login } from './views/login/Login';
import { ResetPassword } from './views/login/ResetPassword';
import { SetPassword } from './views/set.password/SetPassword';
import { NotFound } from './views/not.found/NotFound';
import { Users } from './views/users/Users';
import { EditUser } from './views/users/user.edit/EditUser';
import { AccountableCareNetworks } from './views/accountable.care.networks/AccountableCareNetworks';
import { Providers } from './views/providers/Providers';
import { ImportProviders } from './views/providers/import/providers.import/ImportProviders';
import { EditProgram } from './views/programs/EditProgram';
import { Groups } from './views/groups/Groups';
import { EditGroup } from './views/groups/EditGroup';
import { EditSubgroup } from './views/groups/EditSubgroup';
import { Programs } from './views/programs/Programs';
import { Cycles } from './views/cycles/Cycles';
import { EditCycle } from './views/cycles/EditCycle';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './store/configureStore';
import { userMessageText, isLoggedOut } from './reducers/rootReducer';
import { ClearUserMessageAction } from './actions/userMessageAction';
import { isAuthenticated, logOut } from './services/auth/auth';
import { Permissions } from './constants/Permissions';
import { EditAccountableCareNetwork } from './views/accountable.care.networks/accountable.care.networks.edit/EditAccountableCareNetwork';
import { EditProvider } from './views/providers/provider.edit/EditProvider';
import { ProvidersImportResult } from './views/providers/import.result/providers.import.result/ProvidersImportResult';
import { Reports } from './views/reports/Reports';
import { ViewReport } from './views/reports/reports.view/ViewReport';
import { ImportFacilitySites } from './views/providers/import/facility.sites.import/ImportFacilitySites';
import { FacilitySitesImportResult } from './views/providers/import.result/facility.sites.import.result/FacilitySitesImportResult';
import { ListInfo } from './views/info/ListInfo';
import { ViewInfo } from './views/info/info.view/ViewInfo';
import { ImportRoster } from './views/providers/import/roster.import/ImportRoster';
import { RosterImportResult } from './views/providers/import.result/roster.import.result/RosterImportResult';
import { EditProduct } from './views/products/EditProduct';
import { Products } from './views/products/Products';
import { FeatureFlags } from './constants/FeatureFlags';
import { useFeatureFlags } from './hooks/useFeatureFlags';
import { ImportACNs } from './views/accountable.care.networks/import/import.acns/ImportACNs';
import { ACNsImportResult } from './views/accountable.care.networks/import.result/ACNsImportResult';
import { SetLoginRedirectAction } from './actions/loginRedirectAction';
import { ApiUrls } from './constants/ApiUrls';
import { makeJSONGetRequest } from './services/ajax/ajax';
import { EditProviderDesignation } from './components/providers/provider.designations/provider.designations.edit/EditProviderDesignation';
import { Files } from './views/files/Files';
import { FileUpload } from './views/files/file.upload/FileUpload';

interface PrivateRoute {
    path: string;
    element: FC<any>;
    permission?: string;
}

interface FeatureFlaggedPrivateRoute extends PrivateRoute {
    featureFlag: string;
}

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const featureFlags = useFeatureFlags();
    const message = useSelector<AppState, string>(userMessageText);
    const logOutStatus = useSelector<AppState, boolean>(isLoggedOut);
    const { REACT_APP_TIMEZONE } = process.env;
    const [environmentIndicator, setEnvironmentIndicator] = useState<string>("");

    const privateRoutes: PrivateRoute[] = [
        { path: ApplicationRoutes.USERS, element: Users },
        { path: ApplicationRoutes.EDIT_USER, element: EditUser },
        { path: ApplicationRoutes.ACCOUNTABLE_CARE_NETWORKS, element: AccountableCareNetworks, permission: Permissions.CAN_VIEW_ACNS },
        { path: ApplicationRoutes.EDIT_ACCOUNTABLE_CARE_NETWORKS, element: EditAccountableCareNetwork, permission: Permissions.CAN_VIEW_ACNS },
        { path: ApplicationRoutes.IMPORT_ACNS, element: ImportACNs, permission: Permissions.CAN_VIEW_IMPORT_ACN_DATA },
        { path: ApplicationRoutes.VIEW_IMPORT_ACNS_RESULT, element: ACNsImportResult, permission: Permissions.CAN_VIEW_IMPORT_ACN_DATA },
        { path: ApplicationRoutes.PROVIDERS, element: Providers, permission: Permissions.CAN_VIEW_PROVIDERS },
        { path: ApplicationRoutes.RELEASE_PROVIDER, element: Providers, permission: Permissions.CAN_VIEW_PROVIDERS },
        { path: ApplicationRoutes.EDIT_PROVIDER, element: EditProvider, permission: Permissions.CAN_VIEW_PROVIDERS },
        { path: ApplicationRoutes.EDIT_DESIGNATION, element: EditProviderDesignation, permission: Permissions.CAN_VIEW_PROVIDERS },
        { path: ApplicationRoutes.IMPORT_FACILITY_SITES, element: ImportFacilitySites, permission: Permissions.CAN_VIEW_IMPORT_PROVIDER_DATA },
        { path: ApplicationRoutes.VIEW_IMPORT_FACILITY_SITES_RESULT, element: FacilitySitesImportResult, permission: Permissions.CAN_VIEW_IMPORT_PROVIDER_DATA },
        { path: ApplicationRoutes.IMPORT_PROVIDERS, element: ImportProviders, permission: Permissions.CAN_VIEW_IMPORT_PROVIDER_DATA },
        { path: ApplicationRoutes.VIEW_IMPORT_PROVIDERS_RESULT, element: ProvidersImportResult, permission: Permissions.CAN_VIEW_IMPORT_PROVIDER_DATA },
        { path: ApplicationRoutes.IMPORT_ROSTER, element: ImportRoster, permission: Permissions.CAN_VIEW_IMPORT_ROSTER_DATA },
        { path: ApplicationRoutes.VIEW_IMPORT_ROSTER_RESULT, element: RosterImportResult, permission: Permissions.CAN_VIEW_IMPORT_ROSTER_DATA },
        { path: ApplicationRoutes.PROGRAMS, element: Programs, permission: Permissions.CAN_SEE_PROGRAMS_PAGE },
        { path: ApplicationRoutes.EDIT_PROGRAM, element: EditProgram, permission: Permissions.CAN_SEE_PROGRAMS_PAGE },
        { path: ApplicationRoutes.CYCLES, element: Cycles, permission: Permissions.CAN_VIEW_CYCLES },
        { path: ApplicationRoutes.EDIT_CYCLE, element: EditCycle, permission: Permissions.CAN_VIEW_CYCLES },
        { path: ApplicationRoutes.REPORTS, element: Reports, permission: Permissions.CAN_VIEW_REPORTS },
        { path: ApplicationRoutes.VIEW_REPORT, element: ViewReport, permission: Permissions.CAN_VIEW_REPORTS },
        { path: ApplicationRoutes.FILES, element: Files, permission: Permissions.CAN_VIEW_FILES },
        { path: ApplicationRoutes.UPLOAD_FILE, element: FileUpload, permission: Permissions.CAN_WRITE_FILES },
        { path: ApplicationRoutes.INFO, element: ListInfo },
        { path: ApplicationRoutes.VIEW_INFO, element: ViewInfo }
    ];

    const featureFlaggedPrivateRoutes: FeatureFlaggedPrivateRoute[] = [
        { featureFlag: FeatureFlags.GROUPS, path: ApplicationRoutes.GROUPS, element: Groups, permission: Permissions.CAN_VIEW_GROUPS },
        { featureFlag: FeatureFlags.GROUPS, path: ApplicationRoutes.EDIT_GROUP, element: EditGroup, permission: Permissions.CAN_VIEW_GROUPS },
        { featureFlag: FeatureFlags.GROUPS, path: ApplicationRoutes.EDIT_SUBGROUP, element: EditSubgroup, permission: Permissions.CAN_VIEW_GROUPS },
        { featureFlag: FeatureFlags.PRODUCTS, path: ApplicationRoutes.PRODUCTS, element: Products, permission: Permissions.CAN_SEE_PRODUCTS_PAGE },
        { featureFlag: FeatureFlags.PRODUCTS, path: ApplicationRoutes.EDIT_PRODUCT, element: EditProduct, permission: Permissions.CAN_SEE_PRODUCTS_PAGE }
    ];

    useEffect(() => {
        moment.tz.setDefault(REACT_APP_TIMEZONE);
        console.log(moment().format());
    }, []);

    useEffect(() => {
        if (message && message.length > 0) {
            dispatch(ClearUserMessageAction());
        }
    }, [location.pathname]);

    useLayoutEffect(() => {
        if (logOutStatus && isAuthenticated()) {
            logOut(dispatch, false);
            navigate(ApplicationRoutes.LOGIN);
            dispatch(SetLoginRedirectAction(location.pathname));
        }
    }, [dispatch, logOutStatus]);

    useEffect(() => {
        const getEnvironmentIndicator = async () => {
            var response = await makeJSONGetRequest(ApiUrls.GET_ENVIRONMENT_INDICATOR, dispatch, null, false, false);
            setEnvironmentIndicator(response.body.environmentIndicator);
        }
        getEnvironmentIndicator();
    }, []);

    const renderPrivateRoutes = (): JSX.Element[] => {
        const enabledPrivateRoutes = featureFlaggedPrivateRoutes.filter((featureFlaggedRoute) => !featureFlags || featureFlags[featureFlaggedRoute.featureFlag]);
        const renderedPrivateRoutes = privateRoutes.concat(enabledPrivateRoutes);
        return renderedPrivateRoutes.map((privateRoute) => {
            return <Fragment key={privateRoute.path}>
                <Route path={privateRoute.path} element={<PrivateRoute Component={privateRoute.element} permission={privateRoute.permission} />} />
                <Route path={privateRoute.path + ACTING_FOR_ID} element={<PrivateRoute Component={privateRoute.element} permission={privateRoute.permission} />} />
            </Fragment>
        });
    };

    return (
        <>
            {environmentIndicator && <div className="environment-indicator" style={{ backgroundColor: environmentIndicator }} />}
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path={ApplicationRoutes.ROOT_ROUTE} element={<Login />} />
                    <Route path={ApplicationRoutes.LOGIN} element={<Login />} />
                    <Route path={ApplicationRoutes.SET_PASSWORD} element={<SetPassword />} />
                    <Route path={ApplicationRoutes.RESET_PASSWORD} element={<ResetPassword />} />
                    {renderPrivateRoutes()}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </ThemeProvider>
        </>
    );
}

export default App;
