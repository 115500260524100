import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { File } from '../../interfaces/ApiInterfaces';
import { Permissions } from '../../constants/Permissions';
import { getUserName, hasPermissions } from '../../services/auth/auth';
import { FileOperationStatus } from '../../constants/FileStatus';
import { useActingFor } from '../../hooks/useActingFor';
import { useDispatch } from 'react-redux';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { ApiUrls } from '../../constants/ApiUrls';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { SetFetchNotificationsAction } from '../../actions/fetchNotificationsAction';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { getLabel } from '../common/label/Label.library';
import { APIButton } from '../common/button/APIButton';
import { DateTime } from '../../constants/DateTime';
import './AttestationFileDetails.css';

interface Props {
    open: boolean;
    file: File;
    attestationText: string;
    updateIndividualFileInList: (updatedFile: File) => void;
}

interface FileAttestation {
    attestationText: string;
    verifiedBy: string;
    verifiedAt: string;
}

export const AttestationFileDetails : React.FC<Props> = ({ open, file, attestationText, updateIndividualFileInList }) => {
    const acnActingFor = useActingFor();
    const dispatch = useDispatch();
    
    const canAttest = hasPermissions(Permissions.CAN_ATTEST_FILES);
    const canReject = hasPermissions(Permissions.CAN_REJECT_FILES);

    const operationStatus = new FileOperationStatus(file?.operationStatus);
    const isUnattestable = !operationStatus.isAttestable(); //possible right after removed attestation on a file with type no longer configured as "attestable"
    const isAttestedInUse = operationStatus.code === FileOperationStatus.ATTESTED_IN_USE;
    const isAttestedRejected = operationStatus.code === FileOperationStatus.ATTESTED_REJECTED;
    const isAttested = isAttestedInUse || isAttestedRejected;
    
    const [isAttestationConfirmed, updateIsAttestationConfirmed] = useState(false);
    const [attestation, updateAttestation] = useState<FileAttestation>();
    const [rejectComment, updateRejectComment] = useState<string>();

    useEffect(() => {
        if (open && !!file) {
            updateIsAttestationConfirmed(!!file.attestationText);
            updateAttestation({ attestationText: file.attestationText, verifiedBy: file.verifiedBy, verifiedAt: file.verifiedAt });
            updateRejectComment(file.rejectComment ?? undefined);
        }
    }, [file, open]);

    const confirmAttestation = async () => {
        const data = {
            FileName: file.name,
            AcnId: acnActingFor.id
        };
        var response = await makeJSONPostRequest(ApiUrls.CONFIRM_FILE_ATTESTATION, dispatch, data);
        var updatedFile: File = response.body;

        updateAttestation({ attestationText, verifiedBy: updatedFile.verifiedBy, verifiedAt: updatedFile.verifiedAt });
        updateIndividualFileInList(updatedFile);
        dispatch(SetUserMessageSuccessAction('file_attestation_confirm_success_text'));
        dispatch(SetFetchNotificationsAction(true));
    };

    const removeAttestation = async () => {
        const data = {
            FileName: file.name,
            AcnId: acnActingFor.id
        };
        var response = await makeJSONPostRequest(ApiUrls.REMOVE_FILE_ATTESTATION, dispatch, data);
        var updatedFile: File = response.body;
        
        updateIsAttestationConfirmed(!!updatedFile.attestationText);
        updateAttestation({ attestationText: updatedFile.attestationText, verifiedBy: updatedFile.verifiedBy, verifiedAt: updatedFile.verifiedAt });
        updateIndividualFileInList(updatedFile);
        dispatch(SetUserMessageSuccessAction('file_attestation_remove_success_text'));
    };

    const rejectUseByPH = async () => {
        const data = {
            FileName: file.name,
            AcnId: acnActingFor.id,
            RejectComment: rejectComment
        };
        var response = await makeJSONPostRequest(ApiUrls.REJECT_FILE, dispatch, data);
        var updatedFile: File = response.body;

        updateIndividualFileInList(updatedFile);
        dispatch(SetUserMessageSuccessAction('file_attestation_reject_success_text'));
        dispatch(SetFetchNotificationsAction(true));
    };

    return (
        <div className="file-attestation-confirmation">
            <FormControlLabel
                className="attestation-confirmation-checkbox"
                control={
                    <Checkbox
                        className="attestation-confirmation-checkbox-box"
                        color="primary"
                        checked={isAttestationConfirmed}
                        disabled={!canAttest || isAttested || isUnattestable}
                        onChange={(event) => updateIsAttestationConfirmed(event.target.checked)}
                    />
                }
                label={<span dangerouslySetInnerHTML={{ __html: attestation?.attestationText ?? attestationText }} />}
            />
            <span><div className="attestation-confirmation-label">{getLabel("file_attestation_confirmation_verified_by_label")}</div><div>{!!attestation?.attestationText ? attestation.verifiedBy : getUserName()}</div></span>
            <span><div className="attestation-confirmation-label">{getLabel("file_attestation_confirmation_date_label")}</div><div>{!!attestation?.attestationText ? attestation.verifiedAt : moment().format(DateTime.DISPLAY_DATE)}</div></span>
            <div className="attestation-confirmation-button">
                {isAttested &&
                    <APIButton className="red-button"
                        type="button"
                        variant="contained"
                        color="primary"
                        disabled={!canAttest || isAttestedInUse}
                        onClick={removeAttestation}
                    >
                        {getLabel("file_attestation_remove_attestation_button_label")}
                    </APIButton>
                }
                <APIButton className="red-button"
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={!canAttest || isAttested || !isAttestationConfirmed}
                    onClick={confirmAttestation}
                >
                    {getLabel("file_attestation_confirmation_button_label")}
                </APIButton>
            </div>
            <div className="attestation-rejection">
                { isAttested && canReject &&
                    <APIButton
                        type="button"
                        variant="contained" 
                        color="primary"
                        disabled={isAttestedRejected}
                        onClick={rejectUseByPH}
                    >
                        {getLabel("file_attestation_reject_button_label")}
                    </APIButton>
                }
                { isAttested && (canReject || !!rejectComment) &&
                    <TextField
                        className='text-input-field reject-comment'
                        type='text'
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={2}
                        value={rejectComment}
                        disabled={isAttestedRejected}
                        onChange={(event) => updateRejectComment(event.target.value)}
                    />
                }
            </div>
        </div>
    );
};