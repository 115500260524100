import { useSelector } from "react-redux";
import { FeatureFlags } from "../constants/FeatureFlags";
import { BooleanDictionary } from "../interfaces/ApiInterfaces";
import { applicationInfoFeatureFlags } from "../reducers/rootReducer";
import { AppState } from "../store/configureStore";


export function useFeatureFlags() {
    return useSelector<AppState, BooleanDictionary | null>(applicationInfoFeatureFlags);
}

/**
 * Use to check if a specific feature flag is enabled
 * @param featureFlags Current value which you get from the 'useFeatureFlags' hook.
 * @param flag string name of flag to be checked
 */
export function useFeatureFlagCheck(flag: string): Boolean {
    const featureFlags = useFeatureFlags();
    return (!!featureFlags && featureFlags[flag]);
}

export function useHasGroupsFeature(): Boolean {
    const featureFlags = useFeatureFlags();
    return (!!featureFlags && featureFlags[FeatureFlags.GROUPS]);
}

export function useHasProductsFeature(): Boolean {
    const featureFlags = useFeatureFlags();
    return (!!featureFlags && featureFlags[FeatureFlags.PRODUCTS]);
}
