import { Button, Card, CardContent, Container, List, ListItem, Paper } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoSearch, ClearInfoSearchAction, SetInfoSearchAction } from '../../actions/infoSearchAction';
import { ClearUserMessageAction, SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { getLabel } from '../../components/common/label/Label.library';
import { TextInput } from '../../components/common/text.input/TextInput';
import { AuthenticatedLayout } from '../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { ApiUrls } from '../../constants/ApiUrls';
import { infoSearchValues } from '../../reducers/rootReducer';
import { makeJSONPostRequest } from '../../services/ajax/ajax';
import { AppState } from '../../store/configureStore';
import { Info } from '../../interfaces/ApiInterfaces';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { SearchDefaults } from '../../constants/SearchDefaults';
import './ListInfo.css';
import { PagingFooter } from '../../components/common/paging/PagingFooter';
import { useNavigate } from 'react-router-dom';

export const ListInfo: React.FC<any> = (props) => {
    const [infoList, setInfoList] = useState<Info[]>([]);
    const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(0);
    const [page, setPage] = useState(0);
    const searchValues = useSelector<AppState, InfoSearch>(infoSearchValues);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const initGetSearch = async () => {
            const data: any = {
                Search: searchValues.search,
                Skip: 0,
                Take: SearchDefaults.TAKE,
                OrderBy: "SortKey",
                OrderByDir: "ASC"
            };
            const response = await makeJSONPostRequest(ApiUrls.GET_INFO_LIST, dispatch, data, false, false);
            setInfoList(response.body.result);
            setTotalResults(response.body.totalCount);
            setSkip(response.body.skip);
            setTake(response.body.take);
            setPage(1);
        };
        if (totalResults === -1) {
            initGetSearch();
        }
    }, [])

    async function performSearch(values: any) {
        dispatch(SetInfoSearchAction(values.Search));
        await getInfo({ search: values.Search }, true, true)
    }

    async function clearSearchFilters() {
        dispatch(ClearInfoSearchAction());
        await getInfo({ search: '' }, true, true);
    }

    const selectInfo = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, info: Info) => {
        navigate(createRoute(ApplicationRoutes.VIEW_INFO, { id: info.id }))
    }

    const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
        await updatePage(value);
    };

    async function getInfo(values: any, showSucessMessage: boolean = true, showWorkingMessage: boolean = true) {
        const data = {
            Search: values.search,
            Skip: 0,
            Take: SearchDefaults.TAKE,
            OrderBy: "SortKey",
            OrderByDir: "ASC"
        };
        const response = await makeJSONPostRequest(ApiUrls.GET_INFO_LIST, dispatch, data, showSucessMessage, showWorkingMessage);

        if (showSucessMessage) {
            if (response.body.totalCount === 0) {
                dispatch(SetUserMessageSuccessAction(getLabel('info_search_result_none')));
            }
            else if (response.body.totalCount === 1) {
                dispatch(SetUserMessageSuccessAction(getLabel('info_search_result_one')));
            }
            else {
                dispatch(SetUserMessageSuccessAction(getLabel('info_search_result_many', {
                    totalCount: response.body.totalCount,
                })));
            }
        }
        setInfoList(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
        setPage(1);
    }

    async function updatePage(value: number) {
        setPage(value);
        const data: any = {
            Search: searchValues.search,
            skip: skip + ((value - page) * take),
            take: take,
            OrderBy: "SortKey",
            OrderByDir: "ASC"
        };
        const response = await makeJSONPostRequest(ApiUrls.GET_INFO_LIST, dispatch, data);

        dispatch(ClearUserMessageAction());
        setInfoList(response.body.result);
        setTotalResults(response.body.totalCount);
        setSkip(response.body.skip);
        setTake(response.body.take);
        console.log(infoList);
    };

    return (
        <AuthenticatedLayout {...props}>
            <Container maxWidth={false} className="info">
                <h2>{getLabel("info_page_heading")}</h2>
                <Card>
                    <CardContent>
                        <Formik enableReinitialize={true}
                            initialValues={{ Search: searchValues.search }}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                performSearch(values).finally(() => {
                                    actions.setSubmitting(false);
                                });
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div>
                                        <TextInput name="Search" label="info_filter_search" fullwidth={false} />
                                    </div>
                                    <div></div>
                                    <div className="flex">
                                        <span className="flexIndentLeft"></span>
                                        <Button className="button" type="button" disabled={props.isSubmitting} variant="contained" color="primary" onClick={() => { clearSearchFilters(); props.resetForm(); }}>{getLabel('clear_search_filter_button_label')}</Button>
                                        <Button className="button" type="submit" disabled={props.isSubmitting} variant="contained" color="primary">{getLabel('info_filter_search_button')}</Button>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
                <div>
                    <List id="resultList">
                        {infoList.map((info: Info) => <Paper key={info.id}> <ListItem className="row" button onClick={(event) => selectInfo(event, info)}>
                            <div className="name">{info.title}</div>
                            <div className="content">
                                <span><div dangerouslySetInnerHTML={{ __html: info.content }}></div></span>
                            </div>
                        </ListItem></Paper>)}
                    </List>
                    {totalResults === 0 && <p className="paging">{getLabel("info_search_result_none")}</p>}
                    {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
                </div>
            </Container>
        </AuthenticatedLayout>
    )
}