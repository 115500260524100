import React, { useEffect, useState } from 'react';
import { AuthenticatedLayout } from '../../../../components/layouts/authenticated.layout/AuthenticatedLayout';
import { Container, CardContent, Card, List, Paper, ListItem } from '@mui/material';
import './ImportProviders.css';
import { getLabel } from '../../../../components/common/label/Label.library';
import { Formik } from 'formik';
import { createRoute, ApplicationRoutes } from '../../../../constants/ApplicationRoutes';
import { makeJSONPostRequest, makeJSONPostFileRequest } from '../../../../services/ajax/ajax';
import { useDispatch } from 'react-redux';
import { ApiUrls } from '../../../../constants/ApiUrls';
import { SetUserMessageSuccessAction, SetUserMessageErrorAction, ClearUserMessageAction } from '../../../../actions/userMessageAction';
import { ImportResult } from '../../../../interfaces/ApiInterfaces';
import { SearchDefaults } from '../../../../constants/SearchDefaults';
import { formatDatetime } from '../../../../services/date/date';
import { validate } from '../../../../components/common/import.library/Import.library';
import { ImportFileInput } from '../../../../components/common/import.file.input/ImportFileInput';
import { InfoTitles } from '../../../../constants/InfoTitles';
import { PageHeading } from '../../../../components/common/page.heading/PageHeading';
import { PagingFooter } from '../../../../components/common/paging/PagingFooter';
import { Permissions } from '../../../../constants/Permissions';
import { hasPermissions } from '../../../../services/auth/auth';
import { useNavigate } from 'react-router-dom';

export const ImportProviders: React.FC<any> = (props) => {
  const [importSuccess, setImportSuccess] = useState(false);
  const [providerImportResults, setProviderImportResults] = useState<ImportResult[]>([]);
  const [totalResults, setTotalResults] = useState(-1); // Use -1 to flag no query sent yet. Don't display no results found
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cancelEdit = () => {
    navigate(createRoute(ApplicationRoutes.PROVIDERS));
  };

  const importProviders = async (values: any, actions: any) => {
    var data = new FormData();
    data.append("formFile", values.File);
    data.append("fileName", values.File.name);
    data.append('notes', values.Notes);
    try {
      const response = await makeJSONPostFileRequest(ApiUrls.IMPORT_PROVIDERS, dispatch, data);
      if (response.response.ok) {
        setImportSuccess(true);
        actions.resetForm();
        await getImportResults(1, false);
        dispatch(SetUserMessageSuccessAction('import_result_success'));
      }
    } catch (error: any) {
      if (error.status === 401) dispatch(SetUserMessageErrorAction('provider_failed_import_unauthorized'));
      actions.setSubmitting(false);
    }
  }

  const selectImportResult = (event: React.ChangeEvent<any>, importResult: ImportResult) => {
    navigate(createRoute(ApplicationRoutes.VIEW_IMPORT_PROVIDERS_RESULT, { id: importResult.id }));
  }

  const getImportResults = async (newPage: number, showWorkingMessage: boolean = true) => {
    setProviderImportResults([]);
    setTotalResults(-1);
    const data = {
      Skip: (newPage - 1) * SearchDefaults.TAKE,
      Take: SearchDefaults.TAKE,
      OrderBy: "ImportStartedAt",
      OrderByDir: "DESC"
    };

    const response = await makeJSONPostRequest(ApiUrls.GET_IMPORT_PROVIDERS_RESULTS, dispatch, data, showWorkingMessage);
    setProviderImportResults(response.body.result);
    setTotalResults(response.body.totalCount);
    setPage(newPage);
  }

  const handlePageChange = async (event: React.ChangeEvent<unknown>, page: number) => {
    await getImportResults(page, true);
    dispatch(ClearUserMessageAction());
  };

  useEffect(() => {
    document.title =`${getLabel('app_name')} ${getLabel('title_import_providers')}`
  }, []);

  useEffect(() => {
    getImportResults(1, false);
  }, []);

  return (
    <AuthenticatedLayout {...props} infoTitle={InfoTitles.IMPORT_PROVIDERS}>
      <Container maxWidth={false} className="import-providers">
        <h2><PageHeading to={createRoute(ApplicationRoutes.PROVIDERS)} parentHeading={getLabel('providers_page_heading')}>{getLabel('provider_import_page_heading')}</PageHeading></h2>
        <Card>
          <CardContent>
            <Formik
              initialValues={{ File: null, Notes: '' }}
              validate={validate}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, actions) => {
                setImportSuccess(false);
                importProviders(values, actions);
              }}>
              {(props) => (
                <form className="import-providers-upload" encType='multipart/form-data' onSubmit={props.handleSubmit}>
                  <ImportFileInput name="File" notes="Notes" clear={importSuccess} onCancel={cancelEdit} disableSubmit={!props.dirty || props.isSubmitting || !!props.errors.File} disable={!hasPermissions(Permissions.CAN_IMPORT_PROVIDER_DATA)} />
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
        <div className="import-result-list">
          <List id="resultList">
                      {providerImportResults.map((result) => <Paper key={result.id}> <ListItem className="row" button onClick={(e) => selectImportResult(e, result)} disabled={!result.isComplete}>
              <div className="import-result-row">
                <div className="name">{formatDatetime(result.importStartedAt)}</div>
                <div className="import-result-row-data">
                  <span>
                    <div className="colHeader">{getLabel("import_results_list_imported_by_header")}</div>
                    <div>{result.importedBy.name}</div>
                  </span>
                  <span>
                    <div className="colHeader">{getLabel("import_results_list_filename_header")}</div>
                    <div>{result.importFilename}</div>
                  </span>
                  { 
                    result.isComplete
                    ? <>
                      <span>  
                        <div className="colHeader center">{getLabel("import_results_list_error_count_header")}</div>
                        <div className="center">{result.errorCount}</div>
                      </span>
                      <span>
                        <div className="colHeader center">{getLabel("import_results_list_new_count_header")}</div>
                        <div className="center">{result.newCount}</div>
                      </span>
                      <span>
                        <div className="colHeader center">{getLabel("import_results_list_updated_count_header")}</div>
                        <div className="center">{result.updatedCount}</div>
                      </span>
                      <span>
                        <div className="colHeader center">{getLabel("import_results_list_unchanged_count_header")}</div>
                        <div className="center">{result.unchangedCount}</div>
                      </span>
                      <span>
                        <div className="colHeader center">{getLabel('import_results_list_deleted_count_header')}</div>
                        <div className="center">{result.deletedCount}</div>
                      </span>
                    </>
                    : <span className="import-incomplete">
                      <div>{getLabel("import_results_list_import_incomplete")}</div>
                    </span>
                  }
                </div>
              </div>
            </ListItem></Paper>)}
          </List>
          {totalResults === 0 && <p className="paging">{getLabel("provider_import_result_none")}</p>}
          {totalResults > 0 && <PagingFooter totalResults={totalResults} page={page} onPageChange={handlePageChange} />}
        </div>
      </Container>
    </AuthenticatedLayout>
  );
};
