import React, { useState, useEffect, useRef } from 'react';
import { getLabel } from '../common/label/Label.library';
import { Formik, FormikProps } from 'formik';
import { Button, CardContent, Card } from '@mui/material';
import { makeJSONPostRequest, makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { DateTime } from '../../constants/DateTime';
import { TextInput } from '../common/text.input/TextInput';
import { useDispatch } from 'react-redux';
import { ApplicationRoutes, createRoute } from '../../constants/ApplicationRoutes';
import moment from 'moment-timezone';
import { SetCycleDropdownAction } from '../../actions/cycleDropdownAction';
import { Cycle } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { CheckboxInput } from '../common/checkbox.input/CheckboxInput';
import { SetFetchNotificationsAction } from '../../actions/fetchNotificationsAction';
import { hasPermissions } from '../../services/auth/auth';
import { Permissions } from '../../constants/Permissions';
import { DialogModal } from '../common/dialog.modal/DialogModal';
import { ClearNextCycleAction } from '../../actions/nextCycleAction';
import { SetSuccessMessageAction } from '../../actions/successMessageAction';
import { SetUserMessageSuccessAction } from '../../actions/userMessageAction';
import { useACNDropdownValues } from '../../hooks/useACNDropdownValues';
import { SelectInput } from '../common/select.input/SelectInput';

interface Props {
    cycle: Cycle,
    getCycle: any
}

export const CycleInfo: React.FC<Props> = ({ cycle, getCycle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const acnValues = useACNDropdownValues();
    const [initialCycle, setInitialCycle] = useState<Cycle>();
    const [openUnlockDialog, setOpenUnlockDialog] = useState(false);
    const formikRef = useRef<FormikProps<{ Name: string; SnapshotDate: string; StartDate: string; EndDate: string; Locked: boolean; DedicatedACN: number }>>(null);

    useEffect(() => {
        const getInitialValue = async () => {
            var result;
            if (cycle.id === -1) {
                result = await makeJSONGetRequest(ApiUrls.GET_LATEST_CYCLE, dispatch, null, false, false);
                const latestCycle: Cycle = result.body;
                if (!!result && !!latestCycle.name) {
                    const startDate = moment().isBefore(moment(latestCycle.endAt)) ? moment(latestCycle.endAt).add(1, 'd').format(DateTime.DATE) : moment().format(DateTime.DATE);
                    const endDate = moment(startDate).add(moment(latestCycle.endAt).add(1, 'd').diff(moment(latestCycle.startAt), 'M'), 'M').subtract(1, 'd').format(DateTime.DATE);
                    var snapshotDate = '';
                    if (moment(latestCycle.snapshotAt).date() == moment(latestCycle.startAt).date()) {
                        snapshotDate = moment(startDate).add(moment(latestCycle.snapshotAt).diff(moment(latestCycle.startAt), 'M'), 'M').format(DateTime.DATE);
                    }
                    else {
                        snapshotDate = moment(startDate).add(moment(latestCycle.snapshotAt).diff(moment(latestCycle.startAt), 'd'), 'd').format(DateTime.DATE);
                    }
                    setInitialCycle({ id: -1, startAt: startDate, endAt: endDate, snapshotAt: snapshotDate, name: '', locked: false });
                }
                else {
                    setInitialCycle({ id: -1, startAt: moment().format(DateTime.DATE), endAt: moment().add(1, 'M').subtract(1, 'd').format(DateTime.DATE),
                        snapshotAt: moment().subtract(1, 'd').format(DateTime.DATE), name: '', locked: false });
                }
            }
            else {
                setInitialCycle({ id: cycle.id, startAt: cycle.startAt, endAt: cycle.endAt, snapshotAt: cycle.snapshotAt, name: cycle.name, locked: cycle.locked, dedicatedAcnId: cycle.dedicatedAcnId });
            }
        };
        getInitialValue();
    }, [cycle]);

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};

        if (!values.Name.trim()) {
            errors.Name = getLabel('validation_message_required');
        }
        if (!values.SnapshotDate) {
            errors.SnapshotDate = getLabel('validation_message_required');
        }
        if (!values.StartDate) {
            errors.StartDate = getLabel('validation_message_required');
        }
        if (!values.EndDate) {
            errors.EndDate = getLabel('validation_message_required');
        }
        if (moment(values.StartDate).isSameOrAfter(moment(values.EndDate))) {
            errors.StartDate = getLabel('validation_message_end_after_start');
            errors.EndDate = getLabel('validation_message_end_after_start');
        }
        return errors;
    }

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.CYCLES));
    }

    const saveCycle = async (values: any, actions: any) => {
        if (!values.Locked && cycle.locked) {
            setOpenUnlockDialog(true);
        }
        else {
            const data = {
                Id: cycle.id,
                Name: values.Name,
                SnapshotAt: values.SnapshotDate,
                StartAt: values.StartDate,
                EndAt: values.EndDate,
                Locked: values.Locked,
                DedicatedACNId: !!values.DedicatedACN && +values.DedicatedACN !== -1 ? +values.DedicatedACN : null
            };
            try {
                (cycle.id === -1) ?
                    await makeJSONPostRequest(ApiUrls.CREATE_CYCLE, dispatch, data) :
                    await makeJSONPostRequest(createUrl(ApiUrls.EDIT_CYCLE, { cycleId: cycle.id }), dispatch, data);

                dispatch(SetFetchNotificationsAction(true));
                dispatch(SetSuccessMessageAction((cycle.id === -1) ? 'cycle_success_add_text' : 'cycle_success_edit_text'));
                dispatch(SetCycleDropdownAction(null));
                dispatch(ClearNextCycleAction());
                navigate(createRoute(ApplicationRoutes.CYCLES));
            }
            catch (error: any) {
                actions.setTouched({});
                actions.setSubmitting(false);
            }
        }
    }

    const unlockCycle = async () => {
        const data = {
            Id: cycle.id,
            Name: cycle.name,
            SnapshotAt: cycle.snapshotAt,
            StartAt: cycle.startAt,
            EndAt: cycle.endAt,
            Locked: false,
            DedicatedACNId: cycle.dedicatedAcnId
        };
        await makeJSONPostRequest(createUrl(ApiUrls.EDIT_CYCLE, { cycleId: cycle.id }), dispatch, data);
        dispatch(SetFetchNotificationsAction(true));
        dispatch(SetCycleDropdownAction(null));
        getCycle();
        setOpenUnlockDialog(false);
        dispatch(SetUserMessageSuccessAction(getLabel('cycle_attributes_unlock_success')));
    }

    const cancelUnlock = () => {
        formikRef.current?.resetForm();
        setOpenUnlockDialog(false);
    }

    return (
        <>
            {!!initialCycle &&
                <Card>
                    <CardContent>
                        <DialogModal id="unlockDialog" title={getLabel("unlock_dialog_title")} onClickLeft={cancelUnlock} onClickRight={unlockCycle} open={openUnlockDialog} labelLeft={getLabel("cycle_dialog_do_not_unlock")} labelRight={getLabel("cycle_dialog_unlock")} />
                        <Formik enableReinitialize={true}
                            innerRef={formikRef}
                            initialValues={{ 
                                Name: initialCycle.name ?? '',
                                SnapshotDate: initialCycle.snapshotAt,
                                StartDate: initialCycle.startAt,
                                EndDate: initialCycle.endAt,
                                Locked: initialCycle.locked,
                                DedicatedACN: initialCycle.dedicatedAcnId ?? -1
                            }}
                            validate={validate}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, actions) => {
                                saveCycle(values, actions);
                            }}>
                            {(props) => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className="grid">
                                        <TextInput name="Name" label="cycle_attributes_name_label" fullwidth={false} disabled={cycle.locked} />
                                        <TextInput name="SnapshotDate" label="cycle_attributes_snapshot_label" fullwidth={false} type="date" shrink={true} disabled={cycle.locked} />
                                        <TextInput name="StartDate" label="cycle_attributes_start_label" fullwidth={false} type="date" shrink={true} disabled={cycle.locked} />
                                        <TextInput name="EndDate" label="cycle_attributes_end_label" fullwidth={false} type="date" shrink={true} disabled={cycle.locked} />
                                        <SelectInput name="DedicatedACN" label="cycle_attributes_dedicated_acn_label" fullwidth={false} disabled={cycle.locked}
                                            values={acnValues.map((a: any) => <option key={a.id} value={a.id}>{a.acnName}</option>)}
                                        />
                                    </div>
                                    <div className="flex">
                                        <span className="flexIndentLeft"></span>
                                        <CheckboxInput name="Locked" label="cycle_attributes_locked_label" disabled={!hasPermissions(Permissions.CAN_LOCK_CYCLE)} />
                                        <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>{getLabel('cycle_attributes_cancel_button_label')}</Button>
                                        <Button className="button" type="submit" variant="contained" color="primary" disabled={!props.dirty || props.isSubmitting}>{getLabel('cycle_attributes_submit_button_label')}</Button>
                                    </div>
                                </form>)}
                        </Formik>
                    </CardContent>
                </Card>
            }
        </>
    )
}
