import React, { useEffect, useState } from 'react';
import { Card, CardContent, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getLabel } from '../common/label/Label.library';
import moment from 'moment-timezone';
import { DateTime } from '../../constants/DateTime';
import './CycleAttestation.css';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { useDispatch } from 'react-redux';
import { makeJSONGetRequest, makeJSONPostRequest } from '../../services/ajax/ajax';
import { Cycle } from '../../interfaces/ApiInterfaces';
import CheckIcon from '@mui/icons-material/Check';
import { formatDate } from '../../services/date/date';
import { SetUserMessageErrorAction, SetUserMessageSuccessAction, ClearUserMessageAction } from '../../actions/userMessageAction';
import { getUserId, getUserName, hasPermissions } from '../../services/auth/auth';
import { SetFetchNotificationsAction } from '../../actions/fetchNotificationsAction';
import { Permissions } from '../../constants/Permissions';
import { useActingFor, getActingForDetails } from '../../hooks/useActingFor';
import { APIButton } from '../common/button/APIButton';
import { useFeatureFlagCheck } from '../../hooks/useFeatureFlags';
import { FeatureFlags } from '../../constants/FeatureFlags';

interface Props {
    cycle: Cycle;
}

interface IdNamePair {
    id: number;
    name: string;
}
interface ParticipationProvider extends IdNamePair {
    isPanelClosed: boolean;
}
interface AffiliationIds {
    programId: number;
    productId: number;
    providerId: number;
}
interface CycleAttestationData {
    programs: IdNamePair[];
    products: IdNamePair[];
    providers: ParticipationProvider[];
    affiliations: AffiliationIds[];
    verifiedBy: IdNamePair | null;
    verifiedAt: string;
}

export const CycleAttestation: React.FC<Props> = ({ cycle }) => {
    const dispatch = useDispatch();
    const acnActingFor = useActingFor();
    const isPanelStatusEnabled = useFeatureFlagCheck(FeatureFlags.PANEL_STATUS);

    const [isAttestationConfirmed, updateIsAttestationConfirmed] = useState(false);
    const [attestation, updateAttestation] = useState<CycleAttestationData>();
    const [canProvideAttestation, updateCanProvideAttestation] = useState(true);
    const [isOnOrBeforeLastDayToAttest, updateIsOnOrBeforeLastDayToAttest] = useState(true);
    const canAttest = hasPermissions(Permissions.CAN_ATTEST_TO_PARTICIPATION);
    const canViewRemoveAttestationButton= hasPermissions(Permissions.CAN_VIEW_REMOVE_ATTESTATION_BUTTON);

    const confirmAttestation = async () => {
        try {
            var now = moment();
            const data = {
                AccountableCareNetwork: {
                    Id: acnActingFor.id,
                    ACNName: acnActingFor.acnName
                },
                Cycle: {
                    Id: cycle.id
                },
                ...getActingForDetails(acnActingFor)
            };
            await makeJSONPostRequest(ApiUrls.CONFIRM_CYCLE_PARTICIPATION_ATTESTATION, dispatch, data);
            dispatch(SetUserMessageSuccessAction('cycle_attestation_success_create_text'));
            dispatch(SetFetchNotificationsAction(true));
            updateAttestation({ ...attestation!, verifiedBy: { id: getUserId()!, name: getUserName()! }, verifiedAt: now.format(DateTime.DISPLAY_DATE) });
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('cycle_attestation_failed_create_unauthorized'));
        }
    };

    const removeAttestation = async () => {
        try {
            const data = {
                AccountableCareNetwork: {
                    Id: acnActingFor.id,
                    ACNName: acnActingFor.acnName
                },
                Cycle: {
                    Id: cycle.id
                },
                ...getActingForDetails(acnActingFor)
            };
            await makeJSONPostRequest(ApiUrls.REMOVE_CYCLE_PARTICIPATION_ATTESTATION, dispatch, data);
            dispatch(SetUserMessageSuccessAction('cycle_attestation_success_remove_text'));
            dispatch(SetFetchNotificationsAction(true));
            await getCycleParticipationAndAttestionStatus(cycle.id, acnActingFor.id, false, false)
        } catch (error: any) {
            if (error.status === 401) dispatch(SetUserMessageErrorAction('cycle_attestation_failed_remove_unauthorized'));
        }
    };

    const getCycleParticipationAndAttestionStatus = async (cycleId: number, acnId: number, showMessage: boolean = true, showWorkingMessage: boolean = true) => {
        makeJSONGetRequest(createUrl(ApiUrls.GET_CYCLE_PARTICIPATION, { cycleId, acnId }), dispatch, null, showMessage, showWorkingMessage)
            .then((participationResponse) => {
                var attestationInfo: CycleAttestationData = participationResponse.body;
                updateAttestation(attestationInfo);
                updateIsAttestationConfirmed(!!attestationInfo.verifiedBy);
                if (showMessage && showWorkingMessage) {
                    dispatch(ClearUserMessageAction());
                }
            });
    }

    useEffect(() => {
        if (cycle.id !== -1 && acnActingFor.id !== -1) {
            getCycleParticipationAndAttestionStatus(cycle.id, acnActingFor.id, true, true);
        }
    }, [cycle.id, acnActingFor.id]);

    useEffect(() => {
        const isOnOrBeforeLastDayToAttest = moment().startOf('day').isSameOrBefore(moment(cycle.snapshotAt));
        updateCanProvideAttestation(!!attestation && !attestation.verifiedBy && isOnOrBeforeLastDayToAttest);
        updateIsOnOrBeforeLastDayToAttest(isOnOrBeforeLastDayToAttest);
    }, [attestation, cycle.snapshotAt]);

    return (
        <>
            {
                !!attestation &&
                <Card>
                    <CardContent>
                        <div className="cycle-attestation">
                            <div className="cycle-attestation-summary">
                                <TableContainer className="attestation-summary-table" component={Paper}>
                                    <Table size="small" stickyHeader >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="cycle-attestation-summary-header">{getLabel("cycle_attestation_summary_provider_label")}</TableCell>
                                                {isPanelStatusEnabled && <TableCell className="cycle-attestation-summary-header">{getLabel("cycle_attestation_summary_panel_status_label")}</TableCell>}
                                                <TableCell className="cycle-attestation-summary-header">{getLabel("cycle_attestation_summary_program_label")}</TableCell>
                                                {attestation.products.map(product => <TableCell key={product.id} className="cycle-attestation-summary-header" align="center">{product.name}</TableCell>)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {attestation.affiliations.length > 0 && attestation.programs.length > 0
                                                ? (
                                                    attestation.providers.map(provider => {
                                                        return (
                                                            attestation.programs.map((program, index) =>  {
                                                                return (
                                                                    <TableRow key={`${provider.id}-${program.id}`} className="cycle-attestation-summary-data truncate">
                                                                        <TableCell className="cycle-attestation-summary-provider truncate">{index === 0 ? provider.name : ''}</TableCell>
                                                                        {isPanelStatusEnabled && 
                                                                            <TableCell className="cycle-attestation-summary-panel-status truncate">
                                                                                {index === 0 ? getLabel(provider.isPanelClosed ? "provider_panel_status_closed" : "provider_panel_status_open") : ''}
                                                                            </TableCell>
                                                                        }
                                                                        <TableCell className="cycle-attestation-summary-program truncate">{program.name}</TableCell>
                                                                        {attestation.products.map(product => <TableCell key={product.id} className="attestation-participation-check" align="center">
                                                                            {attestation.affiliations.some(a => a.productId === product.id && a.programId === program.id && a.providerId === provider.id) && <CheckIcon />}
                                                                        </TableCell>)}
                                                                    </TableRow>
                                                                )
                                                            })
                                                        );
                                                    })
                                                )
                                                : (
                                                    <TableRow>
                                                        <TableCell align={"center"} colSpan={2 + attestation.products.length}>{getLabel("cycle_attestation_summary_no_participation_text")}</TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="cycle-attestation-confirmation">
                                <FormControlLabel
                                    className="attestation-confirmation-checkbox"
                                    control={
                                        <Checkbox
                                            className="attestation-confirmation-checkbox-box"
                                            color="primary"
                                            checked={isAttestationConfirmed}
                                            onChange={(event) => updateIsAttestationConfirmed(event.target.checked)}
                                            disabled={!canAttest || !canProvideAttestation || !cycle.locked || (!!cycle.dedicatedAcnId && cycle.dedicatedAcnId != acnActingFor.id)}
                                        />
                                    }
                                    label={<span dangerouslySetInnerHTML={{ __html: getLabel('cycle_attestation_confirmation_text_label') }} />}
                                />
                                <span><div className="attestation-confirmation-label">{getLabel("cycle_attestation_confirmation_verified_by_label")}</div><div>{attestation.verifiedBy ? attestation.verifiedBy.name : getUserName()}</div></span>
                                <span><div className="attestation-confirmation-label">{getLabel("cycle_attestation_confirmation_date_label")}</div><div>{attestation.verifiedBy ? formatDate(attestation.verifiedAt) : moment().format(DateTime.DISPLAY_DATE)}</div></span>
                                <div className="attestation-confirmation-button">
                                    {canViewRemoveAttestationButton && attestation.verifiedBy &&
                                        <APIButton className="confirm-button red-button" type="button" variant="contained" color="primary" disabled={!canAttest || !isOnOrBeforeLastDayToAttest} onClick={(e: any) => removeAttestation()}>
                                            {getLabel("cycle_attestation_remove_attestation_button_label")}
                                        </APIButton>
                                    }
                                    <APIButton className="confirm-button red-button" type="button" variant="contained" color="primary" disabled={!canAttest || !canProvideAttestation || !isAttestationConfirmed} onClick={(e: any) => confirmAttestation()}>
                                        {getLabel("cycle_attestation_confirmation_button_label")}
                                    </APIButton>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            }
        </>
    );
};
