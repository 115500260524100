import React, { useEffect } from 'react';
import { getLabel } from '../common/label/Label.library';
import { Formik, FormikTouched } from 'formik';
import { Button, CardContent, Card, Checkbox, FormControl, FormGroup, FormLabel, FormControlLabel, FormHelperText } from '@mui/material';
import { makeJSONPostRequest, makeJSONGetRequest } from '../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../constants/ApiUrls';
import { AppState } from '../../store/configureStore';
import { TextInput } from '../common/text.input/TextInput';
import { CheckboxInput } from '../common/checkbox.input/CheckboxInput';
import { useDispatch, useSelector } from 'react-redux';
import { SetRoleDropdownAction } from '../../actions/roleDropdownAction';
import { roleDropdownValues } from '../../reducers/rootReducer';
import './UserAttributes.css';
import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { User, UserRole } from '../../interfaces/ApiInterfaces';
import { useNavigate } from 'react-router-dom';
import { TabValue } from '../../constants/TabValue';
import { Permissions } from '../../constants/Permissions';
import { getClientId } from '../../services/client/client';
import { getUserId, hasPermissions } from '../../services/auth/auth';
import { SetSuccessMessageAction } from '../../actions/successMessageAction';
import { useACNDropdownValues } from '../../hooks/useACNDropdownValues';
import { isEmpty, isValidEmail } from '../../services/validate/validate';

interface Props {
    user: User;
    updateHasACNRole: any;
    getUser: any;
}

export const UserInfo: React.FC<Props> = ({ user, updateHasACNRole, getUser }) => {
    const roleValues = useSelector<AppState, any>(roleDropdownValues);
    const acnValues = useACNDropdownValues();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAdd = user.id === -1;
    const canOnlyAssignAcnRoles = !hasPermissions(Permissions.CAN_ACT_FOR);
    const canUpdateAllFields = isAdd || hasPermissions(Permissions.CAN_ACT_FOR) || user.id !== getUserId();

    useEffect(() => {
        const getRoles = async () => {
            const result = await makeJSONGetRequest(ApiUrls.GET_ROLES, dispatch, null, false, false)
            dispatch(SetRoleDropdownAction(result));
        }
        if (!roleValues) {
            getRoles();
        }
    }, [roleValues, dispatch]);

    const validate = (values: any) => {
        const errors: { [key: string]: string } = {};
        if (isEmpty(values.DisplayName)) {
            errors.DisplayName = getLabel('validation_message_required');
        }
        if (isEmpty(values.Email)) {
            errors.Email = getLabel('validation_message_required');
        } else if (!isValidEmail(values.Email)) {
            errors.Email = getLabel('validation_message_email');
        }
        if (user.roleIds.length === 0) {
            errors.RoleIds = getLabel('validation_message_required');
        }
        return errors;
    }

    const hasACNRole = () => {
        if (roleValues.body.length > 0) {
            var acnRoles: UserRole[] = roleValues.body.filter((role: UserRole) => role.permissions.some(permission => permission.code === Permissions.CAN_VIEW_ONLY_MY_ACN));
            return acnRoles && acnRoles.some((acnRole) => user.roleIds.some((roleId: number) => roleId === acnRole.id));
        }
    }

    const formClean = (touched: FormikTouched<{ DisplayName: any; Email: any; Active: any; ViewOnly: any; RoleIds: any; }>) => {
        return (touched.DisplayName === undefined &&
            touched.Email === undefined &&
            touched.Active === undefined &&
            touched.ViewOnly === undefined &&
            touched.RoleIds === undefined);
    }

    const updateUserRoles = (
        setFieldValue: (field: string, value: any) => void,
        setFieldTouched: (field: string, isTouched?: boolean) => void
    ) => function (roleIds: number[]) {
        setFieldValue('RoleIds', roleIds);
        setFieldTouched('RoleIds', true);
    }

    const getRoles = (updateUserRoles: (roleIds: number[]) => void) => {
        if (!roleValues) return;
        const nonReadRoles: UserRole[] = roleValues.body.filter((role: UserRole) => !role.isReadRole);
        return (
            <>
                {nonReadRoles.map(role => {
                    return <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={user.roleIds.some((x: number) => x === role.id || x === role.readRoleId)}
                                name={role.name}
                                onChange={(event) => handleRoleCheck(event, updateUserRoles)}
                                disabled={!canUpdateAllFields}
                            />
                        }
                        label={role.name}
                        key={role.id}
                    />
                })}
            </>
        );
    }

    const handleRoleCheck = (event: React.ChangeEvent<HTMLInputElement>, updateUserRoles: (roleIds: number[]) => void) => {
        if (!!roleValues) {
            var roleIds = user.roleIds;
            var changedRole = roleValues.body.find((role: UserRole) => role.name === event.target.name);
            if (event.target.checked) {
                roleIds.push(changedRole.id);
            } else {
                var index = roleIds.findIndex((id: number) => id === changedRole.id || id === changedRole.readRoleId);
                if (index !== -1) {
                    roleIds.splice(index, 1);
                }
            }
            updateUserRoles(roleIds);
        }
    }

    const cancelEdit = () => {
        navigate(createRoute(ApplicationRoutes.USERS));
    }

    const saveUser = async (values: any, actions: any) => {
        try {
            const data: any = {
                DisplayName: values.DisplayName,
                Email: values.Email,
                Active: values.Active,
                ViewOnly: values.ViewOnly,
                RoleIds: values.RoleIds,
                ClientId: getClientId()
            };

            const response = (isAdd) ?
                await makeJSONPostRequest(ApiUrls.REGISTER_USER, dispatch, data) :
                await makeJSONPostRequest(createUrl(ApiUrls.EDIT_USER, { userId: user.id }), dispatch, data);

            dispatch(SetSuccessMessageAction(isAdd ? 'user_success_add_text' : 'user_success_edit_text'));
            if (hasACNRole()) {
                if (canOnlyAssignAcnRoles && acnValues.length === 1 && isAdd) {
                    await makeJSONPostRequest(createUrl(ApiUrls.CREATE_USER_ACN_ASSOCIATION, { userId: response.body.userId, acnId: acnValues[0].id }), dispatch, null, false, false);
                    navigate(createRoute(ApplicationRoutes.USERS));
                }
                else {
                    updateHasACNRole(true);
                    getUser();
                    navigate(createRoute(ApplicationRoutes.EDIT_USER, { id: isAdd ? response.body.userId : user.id, tab: TabValue.USER_ACN }));
                }
            } else {
                navigate(createRoute(ApplicationRoutes.USERS));
            }
        } catch {
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    return (
        <Card>
            <CardContent>
                <Formik enableReinitialize={true}
                    initialValues={{
                        DisplayName: user.name ?? '',
                        Email: user.email ?? '',
                        Active: user.active ?? true,
                        ViewOnly: user.viewOnly ?? false,
                        RoleIds: user.roleIds ?? []
                    }}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, actions) => {
                        saveUser(values, actions);
                    }}>
                    {(props) => (
                        <form className="edit-user-attributes" onSubmit={props.handleSubmit}>
                            <div className="grid">
                                <TextInput name="DisplayName" label="user_attributes_displayName_label" disabled={!canUpdateAllFields} fullwidth={false} />
                                <TextInput name="Email" label="user_attributes_email_label" disabled={!canUpdateAllFields} fullwidth={false} />
                                <FormControl className="roles" error={props.touched && Boolean(props.errors.RoleIds)} name="RoleIds" component="fieldset">
                                    <FormLabel component="legend">{getLabel("user_attributes_roles_label")}</FormLabel>
                                    <FormGroup >
                                        {getRoles(updateUserRoles(props.setFieldValue, props.setFieldTouched))}
                                    </FormGroup>
                                    {props.errors.RoleIds && <FormHelperText className="validateText">{props.errors.RoleIds}</FormHelperText>}
                                </FormControl>
                                <CheckboxInput name="ViewOnly" label="user_attributes_viewOnly_label" heading="user_attributes_access_label" disabled={!canUpdateAllFields} padded={true} />
                            </div>
                            <div className="flex">
                                <span className="flexIndentLeft"></span>
                                <CheckboxInput name="Active" label="user_attributes_active_label" />
                                <Button className="button" type="button" variant="contained" color="primary" onClick={cancelEdit}>{getLabel('user_attributes_cancel_button_label')}</Button>
                                <Button className="button" type="submit" variant="contained" color="primary" disabled={formClean(props.touched) || props.isSubmitting}>{getLabel('user_attributes_submit_button_label')}</Button>
                            </div>
                        </form>)}
                </Formik>
            </CardContent>
        </Card>
    )
}
