import React, { useEffect, useState } from 'react';
import './ViewGroupAttributes.css';
import { Group, ACN } from '../../../interfaces/ApiInterfaces';
import { getLabel } from '../../common/label/Label.library';
import { Card, CardContent } from '@mui/material';
import { makeJSONGetRequest } from '../../../services/ajax/ajax';
import { ApiUrls, createUrl } from '../../../constants/ApiUrls';
import { useDispatch } from 'react-redux';


interface Props {
    group: Group;
    acn: ACN;
}

export const ViewGroupInfo: React.FC<Props> = ({ group, acn }) => {
    const [parentName, setParentName] = useState<string>('');
    const dispatch = useDispatch();

    useEffect(() => {
        const getParent = async () => {
            const result = await makeJSONGetRequest(createUrl(ApiUrls.GET_GROUP, { groupId: group.parentId }), dispatch, null, false, false);
            setParentName(result.body.name);
        }
        if (group.parentId && group.parentId != -1 && parentName === '') {
            getParent()
        }
    }, [group.parentId]);

    return (
        <Card>
            <CardContent>
                <div className='view-only group-view-attributes'>
                    <div>
                        <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_name_label')}</div><div className='group-data'>{group.name}</div></span>
                        <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_email_label')}</div><div className='group-data'>{group.email}</div></span>
                        <span className='view-half-width'>
                            <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_telephone_label')}</div><div className='group-data'>{group.telephone}</div></span>
                            <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_extension_label')}</div><div className='group-data'>{group.extension}</div></span>
                        </span>
                    </div>
                    <div>
                        <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_address1_label')}</div><div className='group-data'>{group.address1}</div></span>
                        <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_address2_label')}</div><div className='group-data'>{group.address2}</div></span>
                        <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_city_label')}</div><div className='group-data'>{group.city}</div></span>
                        <span className='view-half-width'>
                            <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_state_label')}</div><div className='group-data'>{group.state}</div></span>
                            <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_zip_code_label')}</div><div className='group-data'>{group.zipCode}</div></span>
                        </span>
                    </div>
                    <div>
                        <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_acn_label')}</div><div className='group-data'>{acn.acnName}</div></span>
                        {group.parentId && group.parentId != -1 && <span className='groupLabel'><div className='colHeader'>{getLabel('subgroup_attributes_parent_label')}</div><div className='group-data'>{parentName}</div></span>}
                        <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_description_label')}</div><div className='group-data'>{group.description}</div></span>
                    </div>
                    <div>
                        <span className='groupLabel'><div className='colHeader'>{getLabel('group_attributes_esid_label')}</div><div className='group-data'>{group.externalId}</div></span>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
