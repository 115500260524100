import React, { useEffect } from 'react';
import './NotFound.css';
import { Label } from '../../components/common/label/Label';
import { getLabel } from '../../components/common/label/Label.library';

interface Props {}

export const NotFound: React.FC<Props> = () => {

  useEffect(() => {
    document.title =`${getLabel('app_name')} ${getLabel('title_not_found')}`
  }, [])

  return (
    <div>
      <h1>
        <Label label={'not_found_heading'} />
      </h1>
    </div>
  );
};
