import { createTheme } from '@mui/material';

const white = '#FFFFFF';

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        title: { fontFamily: 'var(--font-lato-bold)' }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*, *::before, *::after': {
          transition: 'none !important',
          animation: 'none !important',
        }
      }
    }
  },
  palette: {
    primary: {
      contrastText: white,
      main: '#319db5',
    }
  }
});
