import React from 'react';
import { getLabel } from '../label/Label.library';
import { Checkbox, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import './CheckboxInput.css';
import { useField } from 'formik';

interface Props {
  name: string;
  label: string;
  heading?: string;
  padded?: boolean;
  onToggle?: any;
  disabled?: boolean;
}

export const CheckboxInput: React.FC<Props> = ({ name, label, heading, padded, onToggle, disabled }) => {
  const [field, meta] = useField(name);

    return (
      <div className="checkbox-input">
        <FormControl variant="outlined">
            {heading != null && <FormLabel component="legend">{getLabel(heading)}</FormLabel>}
            <FormControlLabel
                className={padded != null && padded ? 'checkbox-input-field' : 'checkbox-input-field-padded'}
                control={<Checkbox
                    disabled={ disabled}
                    color="primary"
                    name={field.name}
                    id={field.name}
                    checked={field.value}
                    onChange={(event) => {
                      if (!!onToggle) { onToggle(field.value);}
                      field.onChange(event);
                      field.onBlur(event);
                    }}
                />}
                    label={getLabel(label)}
            />
        </FormControl>
      </div>
  );
};
