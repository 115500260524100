import moment from 'moment-timezone';
import { DateTime } from '../../constants/DateTime';

export const formatDate = (value: string) => {
  const date = moment(value);
  const output = moment(date).format(DateTime.DISPLAY_DATE);
  return output;
};

export const formatDatetime = (value: string) => {
  const date = moment.parseZone(value).local();
  const output = date.format(DateTime.DISPLAY_DATETIME);
  return output;
};