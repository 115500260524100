import { createRoute, ApplicationRoutes } from '../../constants/ApplicationRoutes';
import { ApiUrls } from '../../constants/ApiUrls';
import { makePlainPostRequestNoErrorHandling } from '../ajax/ajax';
import { Dispatch } from 'redux';
import { DestroyStoreAction } from '../../actions/rootAction';
import { Permissions } from '../../constants/Permissions';

const LOCAL_STORAGE_USER_ID_KEY: string = 'user_id';
const LOCAL_STORAGE_USER_NAME_KEY: string = 'user_name';
const LOCAL_STORAGE_USER_ACN_ID_KEY: string = 'user_acn_id';
const LOCAL_STORAGE_USER_ACN_NAME_KEY: string = 'user_acn_name';
const LOCAL_STORAGE_USER_ROLES_KEY: string = 'user_roles';
const LOCAL_STORAGE_USER_PERMISSIONS_KEY: string = 'user_permissions';
const LOCAL_STORAGE_USER_VIEW_ONLY_KEY: string = 'user_is_view_only';
const LOCAL_STORAGE_USER_ACTING_FOR_ID_KEY: string = 'user_acting_for_id';
const LOCAL_STORAGE_USER_ACTING_FOR_REASON_KEY: string = 'user_acting_for_reason';

export function getUserId(): number | null {
  const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY);
  return userId ? +userId : null;
}

export function getUserName(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_USER_NAME_KEY);
}

export function getUserAcnId(): number | null {
  const userAcnId = localStorage.getItem(LOCAL_STORAGE_USER_ACN_ID_KEY);
  return userAcnId ? +userAcnId : null;
}

export function getUserAcnName(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_USER_ACN_NAME_KEY);
}

export function getRoles(): string[] {
  const roleString = localStorage.getItem(LOCAL_STORAGE_USER_ROLES_KEY);
  if (!roleString) {
    return [];
  }
  return roleString.split(',');
}

export function getPermissions(): string[] {
  const permissionString = localStorage.getItem(LOCAL_STORAGE_USER_PERMISSIONS_KEY);
  if (!permissionString) {
    return [];
  }
  return permissionString.split(',');
}

export function getIsViewOnly(): boolean {
  const isViewOnly = localStorage.getItem(LOCAL_STORAGE_USER_VIEW_ONLY_KEY);
  return !!isViewOnly && isViewOnly === 'true' ? true : false;
}

export function getActingForId(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_USER_ACTING_FOR_ID_KEY);
}

export function getActingForReason(): string | null {
  return localStorage.getItem(LOCAL_STORAGE_USER_ACTING_FOR_REASON_KEY);
}

export function setUserId(id: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, id);
}

export function setUserName(name: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_NAME_KEY, name);
}

export function setUserAcnId(acnId: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_ACN_ID_KEY, acnId);
}

export function setUserAcnName(acnName: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_ACN_NAME_KEY, acnName);
}

export function setRoles(roles: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_ROLES_KEY, roles);
}

export function setPermissions(permissions: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_PERMISSIONS_KEY, permissions);
}

export function setIsViewOnly(isViewOnly: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_VIEW_ONLY_KEY, isViewOnly);
}

export function setActingForId(acnId: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_ACTING_FOR_ID_KEY, acnId);
}

export function setActingForReason(reason: string): void {
  localStorage.setItem(LOCAL_STORAGE_USER_ACTING_FOR_REASON_KEY, reason);
}

export function hasPermissions(permission: string): boolean {
  return getPermissions().includes(permission);
}

export function isAuthenticated(): boolean {
  return getPermissions().length > 0;
}

export async function logOut(dispatcher: Dispatch, sendLogOutApiRequest: boolean = true): Promise<void> {
  localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_NAME_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_ACN_ID_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_ACN_NAME_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_ROLES_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_PERMISSIONS_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_VIEW_ONLY_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_ACTING_FOR_ID_KEY);
  localStorage.removeItem(LOCAL_STORAGE_USER_ACTING_FOR_REASON_KEY);
  dispatcher(DestroyStoreAction());
  if (sendLogOutApiRequest) {
    await makePlainPostRequestNoErrorHandling(ApiUrls.LOGOUT, dispatcher, null, false);
  }
}

export function getAuthenticatedHomePage(): string {
  if ((hasPermissions(Permissions.CAN_CREATE_USER) || hasPermissions(Permissions.CAN_UPDATE_USER)) &&
    hasPermissions(Permissions.CAN_ACT_FOR)) {
    return createRoute(ApplicationRoutes.USERS);
  } else if (hasPermissions(Permissions.CAN_VIEW_ACNS)) {
    return createRoute(ApplicationRoutes.ACCOUNTABLE_CARE_NETWORKS);
  } else {
    return createRoute(ApplicationRoutes.PROGRAMS);
  }
}

export function getHomePage(): string {
  if (isAuthenticated()) {
    return getAuthenticatedHomePage();
  }
  return ApplicationRoutes.LOGIN;
}
