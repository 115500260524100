import { UserSearch, UserSearchAction, SET_USER_SEARCH, CLEAR_USER_SEARCH } from '../actions/userSearchAction';
import { CLEAR_ACN_SEARCH, ACNSearch, ACNSearchAction, SET_ACN_SEARCH } from '../actions/acnSearchAction';
import { CLEAR_GROUP_SEARCH, GroupSearchValues, GroupSearchAction, SET_GROUP_SEARCH } from '../actions/groupSearchAction';
import { SubgroupSearchValues, SubgroupSearchAction, SET_SUBGROUP_SEARCH, CLEAR_SUBGROUP_SEARCH } from '../actions/subgroupSearchAction';
import { CLEAR_CYCLE_SEARCH, CycleSearch, CycleSearchAction, SET_CYCLE_SEARCH } from '../actions/cycleSearchAction';
import { CLEAR_PROGRAM_SEARCH, ProgramSearch, ProgramSearchAction, SET_PROGRAM_SEARCH } from '../actions/programSearchAction';
import { CLEAR_MY_PROVIDER_SEARCH, MyProviderSearch, MyProviderSearchAction, SET_MY_PROVIDER_SEARCH } from '../actions/myProviderSearchAction';
import { CLEAR_ALL_PROVIDER_SEARCH, AllProviderSearch, AllProviderSearchAction, SET_ALL_PROVIDER_SEARCH } from '../actions/allProviderSearchAction';
import { CLEAR_REPORT_SEARCH, ReportSearch, ReportSearchAction, SET_REPORT_SEARCH } from '../actions/reportSearchAction';
import { CLEAR_INFO_SEARCH, InfoSearch, InfoSearchAction, SET_INFO_SEARCH } from '../actions/infoSearchAction';
import { CLEAR_PRODUCT_SEARCH, ProductSearch, ProductSearchAction, SET_PRODUCT_SEARCH } from '../actions/productSearchAction';
import { CLEAR_USER_ACNS_SEARCH, UserACNsSearch, UserACNsSearchAction, SET_USER_ACNS_SEARCH } from '../actions/userACNsSearchAction';
import { CLEAR_PROGRAM_ACNS_SEARCH, ProgramACNsSearch, ProgramACNsSearchAction, SET_PROGRAM_ACNS_SEARCH } from '../actions/programACNsSearchAction';
import { CLEAR_PRODUCT_ACNS_SEARCH, ProductACNsSearch, ProductACNsSearchAction, SET_PRODUCT_ACNS_SEARCH } from '../actions/productACNsSearchAction';
import { CLEAR_GROUP_PROVIDER_SEARCH, GroupProviderSearch, GroupProviderSearchAction, SET_GROUP_PROVIDER_SEARCH } from '../actions/groupProviderSearchAction';
import { CLEAR_SUBGROUP_PROVIDER_SEARCH, SubgroupProviderSearch, SubgroupProviderSearchAction, SET_SUBGROUP_PROVIDER_SEARCH } from '../actions/subgroupProviderSearchAction';
import { CLEAR_FILE_SEARCH, FileSearch, FileSearchAction, SET_FILE_SEARCH } from '../actions/fileSearchAction';
import { FileOrderBy } from '../constants/FileOrderBy';

const initialUserState: UserSearch = {
    name: '',
    email: '',
    accountableCareNetwork: '',
    roleCode: '',
    includeInactive: false
};

const initialACNState: ACNSearch = {
    acnName: '',
    city: '',
    state: '',
    includeInactive: false,
};

const initialAllProviderState: AllProviderSearch = {
    nationalProviderId: null,
    includeInactive: false
}

const initialMyProviderState: MyProviderSearch = {
    includeInactive: false,
    affiliationStatus: '',
    groupNPI: null,
    firstName: '',
    lastName: ''
}

const initialGroupProviderState: GroupProviderSearch = {
    firstName: '',
    lastName: '',
    groupNPI: null
}

const initialSubgroupProviderState: SubgroupProviderSearch = {
    firstName: '',
    lastName: '',
    groupNPI: null,
    alreadyAddedOnly: false
}

const initialGroupState: GroupSearchValues = {
    name: '',
    city: '',
    state: '',
    includeInactive: false
};

const initialSubgroupState: SubgroupSearchValues = {
    name: '',
    city: '',
    state: '',
    includeInactive: false
};

const initialCycleState: CycleSearch = {
    startDate: '',
    endDate: ''
};

const initialProgramState: ProgramSearch = {
    name: '',
    description: '',
    includeInactive: false
};

const initialReportState: ReportSearch = {
    name: '',
    category: '',
    includeInactive: false
};

const initialInfoSearch: InfoSearch = {
    search: ''
}

const initialProductState: ProductSearch = {
    name: '',
    includeInactive: false
};

const initialUserACNsState: UserACNsSearch = {
    acnName: ''
}

const initialProgramACNsState: ProgramACNsSearch = {
    acnName: ''
}

const initialProductACNsState: ProductACNsSearch = {
    acnName: ''
}

const initialFileSearch: FileSearch = {
    filename: '',
    startDate: '',
    endDate: '',
    type: '',
    validationStatus: '',
    operationStatus: '',
    comment: '',
    orderBy: FileOrderBy.ORDER_BY[0].value,
}

export const userSearch = (
    state: UserSearch = initialUserState,
    action: UserSearchAction
): UserSearch => {
    if (action.type === SET_USER_SEARCH) {
        return { name: action.userSearch.name, email: action.userSearch.email, accountableCareNetwork: action.userSearch.accountableCareNetwork, roleCode: action.userSearch.roleCode, includeInactive: action.userSearch.includeInactive };
    } else if (action.type === CLEAR_USER_SEARCH) {
        return initialUserState;
    }
    return state;

};

export const acnSearch = (
    state: ACNSearch = initialACNState,
    action: ACNSearchAction
): ACNSearch => {
    if (action.type === SET_ACN_SEARCH) {
        return { acnName: action.acnSearch.acnName, city: action.acnSearch.city, state: action.acnSearch.state, includeInactive: action.acnSearch.includeInactive };
    } else if (action.type === CLEAR_ACN_SEARCH) {
        return initialACNState;
    }
    return state;
}

export const allProviderSearch = (
    state: AllProviderSearch = initialAllProviderState,
    action: AllProviderSearchAction
): AllProviderSearch => {
    if (action.type === SET_ALL_PROVIDER_SEARCH) {
        return { nationalProviderId: action.allProviderSearch.nationalProviderId, includeInactive: action.allProviderSearch.includeInactive };
    } else if (action.type === CLEAR_ALL_PROVIDER_SEARCH) {
        return initialAllProviderState;
    }
    return state;
}

export const myProviderSearch = (
    state: MyProviderSearch = initialMyProviderState,
    action: MyProviderSearchAction
): MyProviderSearch => {
    if (action.type === SET_MY_PROVIDER_SEARCH) {
        return { includeInactive: action.myProviderSearch.includeInactive, affiliationStatus: action.myProviderSearch.affiliationStatus, groupNPI: action.myProviderSearch.groupNPI, firstName: action.myProviderSearch.firstName, lastName: action.myProviderSearch.lastName };
    } else if (action.type === CLEAR_MY_PROVIDER_SEARCH) {
        return initialMyProviderState;
    }
    return state;
}

export const groupProviderSearch = (
    state: GroupProviderSearch = initialGroupProviderState,
    action: GroupProviderSearchAction
): GroupProviderSearch => {
    if (action.type === SET_GROUP_PROVIDER_SEARCH) {
        return { firstName: action.groupProviderSearch.firstName, lastName: action.groupProviderSearch.lastName, groupNPI: action.groupProviderSearch.groupNPI };
    } else if (action.type === CLEAR_GROUP_PROVIDER_SEARCH) {
        return initialGroupProviderState;
    }
    return state;
}

export const subgroupProviderSearch = (
    state: SubgroupProviderSearch = initialSubgroupProviderState,
    action: SubgroupProviderSearchAction
): SubgroupProviderSearch => {
    if (action.type === SET_SUBGROUP_PROVIDER_SEARCH) {
        return { firstName: action.subgroupProviderSearch.firstName, lastName: action.subgroupProviderSearch.lastName, groupNPI: action.subgroupProviderSearch.groupNPI, alreadyAddedOnly: action.subgroupProviderSearch.alreadyAddedOnly };
    } else if (action.type === CLEAR_SUBGROUP_PROVIDER_SEARCH) {
        return initialSubgroupProviderState;
    }
    return state;
}

export const groupSearch = (
    state: GroupSearchValues = initialGroupState,
    action: GroupSearchAction
): GroupSearchValues => {
    if (action.type === SET_GROUP_SEARCH) {
        return { name: action.groupSearch.name, city: action.groupSearch.city, state: action.groupSearch.state, includeInactive: action.groupSearch.includeInactive };
    } else if (action.type === CLEAR_GROUP_SEARCH) {
        return initialGroupState;
    }
    return state;
}

export const subgroupSearch = (
    state: SubgroupSearchValues = initialSubgroupState,
    action: SubgroupSearchAction
): SubgroupSearchValues => {
    if (action.type === SET_SUBGROUP_SEARCH) {
        return { name: action.subgroupSearch.name, city: action.subgroupSearch.city, state: action.subgroupSearch.state, includeInactive: action.subgroupSearch.includeInactive };
    } else if (action.type === CLEAR_SUBGROUP_SEARCH) {
        return initialSubgroupState;
    }
    return state;
}

export const cycleSearch = (
    state: CycleSearch = initialCycleState,
    action: CycleSearchAction
): CycleSearch => {
    if (action.type === SET_CYCLE_SEARCH) {
        return { startDate: action.cycleSearch.startDate, endDate: action.cycleSearch.endDate };
    } else if (action.type === CLEAR_CYCLE_SEARCH) {
        return initialCycleState;
    }
    return state;
}

export const programSearch = (
    state: ProgramSearch = initialProgramState,
    action: ProgramSearchAction
): ProgramSearch => {
    if (action.type === SET_PROGRAM_SEARCH) {
        return {
            name: action.programSearch.name,
            description: action.programSearch.description,
            includeInactive: action.programSearch.includeInactive
        };
    } else if (action.type === CLEAR_PROGRAM_SEARCH) {
        return initialProgramState;
    }
    return state;
}

export const reportSearch = (
    state: ReportSearch = initialReportState,
    action: ReportSearchAction
): ReportSearch => {
    if (action.type === SET_REPORT_SEARCH) {
        return { name: action.reportSearch.name, category: action.reportSearch.category, includeInactive: action.reportSearch.includeInactive };
    } else if (action.type === CLEAR_REPORT_SEARCH) {
        return initialReportState;
    }
    return state;
}

export const infoSearch = (
    state: InfoSearch = initialInfoSearch,
    action: InfoSearchAction
): InfoSearch => {
    if (action.type === SET_INFO_SEARCH) {
        return { search: action.infoSearch.search };
    } else if (action.type === CLEAR_INFO_SEARCH) {
        return initialInfoSearch;
    }
    return state;
}

export const productSearch = (
    state: ProductSearch = initialProductState,
    action: ProductSearchAction
): ProductSearch => {
    if (action.type === SET_PRODUCT_SEARCH) {
        return {
            name: action.productSearch.name,
            includeInactive: action.productSearch.includeInactive
        };
    } else if (action.type === CLEAR_PRODUCT_SEARCH) {
        return initialProductState;
    }
    return state;
}

export const userACNsSearch = (
    state: UserACNsSearch = initialUserACNsState,
    action: UserACNsSearchAction
): UserACNsSearch => {
    if (action.type === SET_USER_ACNS_SEARCH) {
        return {
            acnName: action.userACNsSearch.acnName
        };
    } else if (action.type === CLEAR_USER_ACNS_SEARCH) {
        return initialUserACNsState;
    }
    return state;
}

export const programACNsSearch = (
    state: ProgramACNsSearch = initialProgramACNsState,
    action: ProgramACNsSearchAction
): ProgramACNsSearch => {
    if (action.type === SET_PROGRAM_ACNS_SEARCH) {
        return {
            acnName: action.programACNsSearch.acnName
        };
    } else if (action.type === CLEAR_PROGRAM_ACNS_SEARCH) {
        return initialProgramACNsState;
    }
    return state;
}

export const productACNsSearch = (
    state: ProductACNsSearch = initialProductACNsState,
    action: ProductACNsSearchAction
): ProductACNsSearch => {
    if (action.type === SET_PRODUCT_ACNS_SEARCH) {
        return {
            acnName: action.productACNsSearch.acnName
        };
    } else if (action.type === CLEAR_PRODUCT_ACNS_SEARCH) {
        return initialProductACNsState;
    }
    return state;
}

export const fileSearch = (
    state: FileSearch = initialFileSearch,
    action: FileSearchAction
): FileSearch => {
    if (action.type === SET_FILE_SEARCH) {
        return {
            filename: action.fileSearch.filename,
            startDate: action.fileSearch.startDate,
            endDate: action.fileSearch.endDate,
            type: action.fileSearch.type,
            validationStatus: action.fileSearch.validationStatus,
            operationStatus: action.fileSearch.operationStatus,
            comment: action.fileSearch.comment,
            orderBy: action.fileSearch.orderBy
        };
    } else if (action.type === CLEAR_FILE_SEARCH) {
        return initialFileSearch;
    }
    return state;
}

export const getUserSearch = (state: UserSearch) => state;
export const getACNSearch = (state: ACNSearch) => state;
export const getAllProviderSearch = (state: AllProviderSearch) => state;
export const getMyProviderSearch = (state: MyProviderSearch) => state;
export const getGroupProviderSearch = (state: GroupProviderSearch) => state;
export const getSubgroupProviderSearch = (state: SubgroupProviderSearch) => state;
export const getGroupSearch = (state: GroupSearchValues) => state;
export const getSubgroupSearch = (state: SubgroupSearchValues) => state;
export const getCycleSearch = (state: CycleSearch) => state;
export const getProgramSearch = (state: ProgramSearch) => state;
export const getProductSearch = (state: ProductSearch) => state;

export const getReportSearch = (state: ReportSearch) => state;
export const getInfoSearch = (state: InfoSearch) => state;

export const getUserACNsSearch = (state: UserACNsSearch) => state;
export const getProgramACNsSearch = (state: ProgramACNsSearch) => state;
export const getProductACNsSearch = (state: ProductACNsSearch) => state;
export const getFileSearch = (state: FileSearch) => state;
